import { createSlice } from '@reduxjs/toolkit';
import { IProduct } from '../../shared/interfaces/IProduct';


const initialState: IProduct[] = localStorage.getItem('wishlist') ? JSON.parse(localStorage.getItem('wishlist') as string) : [];

const wishlistSlice = createSlice({
    name: 'wishlist',
    initialState,
    reducers: {
        setWishlist: (state, action) => {
            state = action.payload;
            return state;
        }
    },
});

export default wishlistSlice;
