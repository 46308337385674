import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { IUser } from "../../../interfaces/IUser";
import { RootState } from "../../../../store/store";
import { multipartFormData } from "../../../../utils/api/api.service";
import { PROFILE_URL } from "../../../../utils/api/endpoints";
import authSlice from "../../../../store/slices/auth.slice";
import { handleError } from "../../../functions/handleError";
import { toast } from "react-toastify";
import { UsersService } from "../../../../app/admin/users/service";
import { IUpdatePassword } from "../../../interfaces/IUpdatePassword";
import { PasswordForm } from "./components/PasswordForm";
import { PersonalForm } from "./components/PersonalForm";

export default function AccountDetails() {
  const { t } = useTranslation();
  const { userData } = useSelector((state: RootState) => state.auth);
  console.log(userData, "USER DATA");
  const [currentUser, setCurrentUser] = useState<IUser | null>(null);
  const [file, setFile] = useState<File | null>(null);
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const dispatch = useDispatch();

  const [passwordState, setPasswordState] = useState<IUpdatePassword>({
    oldPassword: "",
    newPassword: "",
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCurrentUser((prev) => ({ ...prev, [name]: value } as IUser));
  };

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setPasswordState((prev) => ({ ...prev, [name]: value }));
  };

  const onSubmitPersonal = () => {
    multipartFormData(file, PROFILE_URL, currentUser, "put", "file")
      .then((response) => {
        const { data } = response;
        dispatch(
          authSlice.actions.setLoginState({
            isAuthenticated: true,
            userData: data,
          })
        );
        toast.success(t("general.successfullyUpdated"));
      })
      .catch((err) => handleError(err));
  };

  const onSubmitPassword = () => {
    UsersService.userChangePassword(passwordState, userData?.id as number)
      .then(() => {
        toast.success(t("general.successfullyUpdated"));
        setPasswordState({
          oldPassword: "",
          newPassword: "",
        });
      })
      .catch((err) => handleError(err));
  };

  useEffect(() => {
    if (userData?.id) {
      setCurrentUser(userData);
    }
  }, [userData?.id, userData]);

  return (
    <>
      <Form
        onSubmit={(event) => {
          event.preventDefault();
          onSubmitPersonal();
        }}
      >
        <PersonalForm
          currentUser={currentUser}
          handleChange={handleChange}
          file={file}
          setFile={setFile}
          userData={userData}
        />
      </Form>

      <Form
        onSubmit={(e) => {
          e.preventDefault();
          onSubmitPassword();
        }}
      >
        <PasswordForm
          showPassword={showPassword}
          setShowPassword={setShowPassword}
          handleChangePassword={handleChangePassword}
          passwordState={passwordState}
        />
      </Form>
    </>
  );
}
