import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
import ProductForm from './ProductForm';
import { IProduct } from '../../../../shared/interfaces/IProduct';
import { multipartFormData } from '../../../../utils/api/api.service';
import { ADMIN_PRODUCTS } from '../../../../utils/api/endpoints';
import { handleError } from '../../../../shared/functions/handleError';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export default function CreateProduct() {
    const [currentProduct, setCurrentProduct] = useState<IProduct | null>(null);
    const [files, setFiles] = useState<FileList | null>(null);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleSubmit = () => {
        multipartFormData(files && files, ADMIN_PRODUCTS, currentProduct, 'post', 'files', true).then((response) => {
            console.log(response, "CREATED PRODUCT")
            if (response) {
                toast.success(t("general.successfullyCreated"));
                navigate(-1);
                setCurrentProduct(null);
            }
        }).catch(err => handleError(err));
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setCurrentProduct(prev => ({ ...prev, [name]: value }) as IProduct);
    }

    return (
        <Form onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
        }}>
            <ProductForm currentProduct={currentProduct} setCurrentProduct={setCurrentProduct} handleChange={handleChange} files={files} setFiles={setFiles} />
        </Form>
    )
}
