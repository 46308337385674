export const footerData = [
    {
        title: 'footer.company',
        items: [
            {id: 1, name: 'footer.blog', navigateTo: '/blog'},
            {id: 2, name: 'footer.contactUs', navigateTo: '/contact'},
        ],
    },
    {
        title: 'footer.shop',
        items: [{id: 1, name: 'footer.shopAll', navigateTo: '/shop'}],
    },
    {
        title: 'footer.help',
        items: [
            {id: 1, name: 'footer.legalPrivacy', navigateTo: '/'},
            {id: 2, name: 'navigation.contact', navigateTo: '/contact'},
            {id: 3, name: 'navigation.faq', navigateTo: '/faq'},
            {id: 4, name: 'navigation.returnItemPolicy', navigateTo: '/return-policy'},
            {id: 5, name: 'navigation.pricePolicy', navigateTo: '/price-policy'},
        ],
    },
];
