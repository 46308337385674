import {flatten} from 'lodash'
import React, {useEffect, useState} from 'react'
import {Button} from 'react-bootstrap'
import {FaInstagram} from 'react-icons/fa';
import {handleError} from '../../../shared/functions/handleError'
import {useCachingState} from '../../../shared/hooks/useCachingState';
import {ICategory} from '../../../shared/interfaces/ICategory'
import {webshopData} from '../../../utils/data/webshopData';
import {CategoryService} from '../../admin/categories/service'
import DashboardImageText from './components/DashboardImageText'
import LatestNews from './components/LatestNews'
import MostPopularProducts from './components/MostPopularProducts'
import MostPreferredCategories from './components/MostPreferredCategories'
import ShopNowImage from './components/ShopNowImage'
import WeeklyFeaturedProducts from './components/WeeklyFeaturedProducts'


export default function Home() {
    const [categories, setCategories] = useCachingState<ICategory[]>('homeCategories', []);

    useEffect(() => {
        CategoryService.getAllCategories({page: 1, perPage: 0})
            .then(response => {
                setCategories(response?.data?.filter(category => category?.products?.length));
            }).catch(err => handleError(err))
    }, []);

    return (
        <>
            <div className="row">
                <div className="col-12 px-0 position-relative">
                    <DashboardImageText/>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-12 mt-5">
                        <WeeklyFeaturedProducts products={flatten(categories?.map(category => category?.products))}/>
                    </div>

                    {!!categories?.length && <div className="col-12 mt-5">
                        <MostPreferredCategories categories={categories}/>
                    </div>}

                    {!!categories?.length && <div className="col-12 mt-5">
                        <MostPopularProducts categories={categories}/>
                    </div>}

                    <div className="col-12 my-5 position-relative d-none d-md-block">
                        <ShopNowImage/>
                    </div>

                    <div className="col-12">
                        <LatestNews/>
                    </div>

                    <div className="col-12 d-flex justify-content-center my-5">
                        <a href={webshopData.instagram} target={'_blank'} className={'text-decoration-none'}>
                            <Button variant="dark" className="d-flex align-items-center gap-1 p-3">
                                <FaInstagram/> INSTAGRAM
                            </Button>
                        </a>
                    </div>
                </div>
            </div>

        </>
    )
}
