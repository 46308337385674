import React, { useState } from 'react'
import { Form } from 'react-bootstrap';
import FaqForm from './FaqForm';
import { IFaq } from '../../../../../shared/interfaces/IFaq';
import { FAQService } from '../../service';
import { handleError } from '../../../../../shared/functions/handleError';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export default function CreateFaq() {
    const [currentFaq, setCurrentFaq] = useState<IFaq | null>(null);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleSubmit = () => {
        FAQService.createFaq(currentFaq as IFaq).then((response) => {
            if (response) {
                toast.success(t("general.successfullyCreated"));
                setCurrentFaq(null);
                navigate(-1);
            }
        }).catch(err => handleError(err));
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setCurrentFaq(prev => ({ ...prev, [name]: value }) as IFaq);
    }

    return (
        <Form onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
        }}>
            <FaqForm currentFaq={currentFaq} handleChange={handleChange} />
        </Form>
    )
}
