import React, {SetStateAction, useState} from 'react'
import {Button, Form, Modal} from 'react-bootstrap'
import {ICategory} from '../../../../shared/interfaces/ICategory'
import {handleError} from '../../../../shared/functions/handleError';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';
import ImageHandler from '../../../../shared/components/ImageHandler';
import {multipartFormData} from '../../../../utils/api/api.service';
import {ADMIN_CATEGORIES} from '../../../../utils/api/endpoints';

interface IProps {
    show: boolean,
    setShow: React.Dispatch<SetStateAction<boolean>>,
    currentCategory: ICategory | null,
    setCurrentCategory: React.Dispatch<SetStateAction<ICategory | null>>,
    changeFilterHandler: (name: string, value: string | null | number | boolean) => void
}

export default function CategoryModal({
                                          show,
                                          setShow,
                                          currentCategory,
                                          setCurrentCategory,
                                          changeFilterHandler
                                      }: IProps) {
    const {t} = useTranslation();
    const [file, setFile] = useState<File | null>(null);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {name, value} = event.target;
        setCurrentCategory(prev => ({...prev, [name]: value}) as ICategory);
    }

    const handleSubmit = () => {
        const isUpdate = !!currentCategory?.id;

        multipartFormData(file, isUpdate ? `${ADMIN_CATEGORIES}/${currentCategory?.id}` : ADMIN_CATEGORIES, {
            name: currentCategory?.name
        }, isUpdate ? 'put' : 'post', 'file').then((response) => {
            if (response) {
                toast.success(t(`general.successfully${isUpdate ? 'Updated' : 'Created'}`))
                setShow(false);
                changeFilterHandler('updatedAt', new Date().getTime());
                setCurrentCategory(null);
                setFile(null);
            }
        }).catch(err => handleError(err))
    }

    return (
        <Modal show={show} centered size={'lg'}>
            <Modal.Header>
                <Modal.Title> {currentCategory?.id ? t('buttons.update') : t('buttons.create')} </Modal.Title>
            </Modal.Header>

            <Form onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
            }}>
                <Modal.Body>
                    <Form.Control type="text"
                                  name="name"
                                  value={currentCategory?.name}
                                  className="rounded-0 p-3"
                                  onChange={handleChange}
                                  placeholder={t('general.categoryName')}
                                  required/>

                    <input type="file"
                           className="form-control mt-2 rounded-0 p-3"
                           onChange={(e) => setFile(e?.target?.files?.[0] as File)}
                           required={!currentCategory?.id}/>

                    {(file || currentCategory?.path) &&
                        <ImageHandler src={String(file ? URL.createObjectURL(file) : currentCategory?.path)}
                                      className="w-100 h-200px object-fit-cover mt-2"/>}
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="dark" onClick={() => {
                        setShow(false);
                        setCurrentCategory(null);
                    }} className="text-uppercase">
                        {t('buttons.cancel')}
                    </Button>
                    <Button variant="primary" type="submit" className="text-uppercase">
                        {t(`buttons.${currentCategory?.id ? 'update' : 'create'}`)}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    )
}
