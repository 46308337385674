import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ImageHandler from "../../../shared/components/ImageHandler";
import { handleError } from "../../../shared/functions/handleError";
import { IProduct } from "../../../shared/interfaces/IProduct";
import { RootState } from "../../../store/store";
import { ProductService } from "../../admin/products/service";
import ProductFancybox from "./components/ProductFancybox";
import ProductInfo from "./components/ProductInfo";
import ProductRelated from "./components/ProductRelated";
import { useTranslation } from "react-i18next";

const SingleProduct = () => {
  const { id } = useParams();
  const cart = useSelector((state: RootState) => state.cart);
  const [activeImageId, setActiveImageId] = useState(0);
  const [product, setProduct] = useState<IProduct | null>(null);
  const currentImage = product?.productPhotos?.find(
    (img) => img.id === activeImageId
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (id) {
      ProductService.getProductById(Number(id))
        .then((response) => {
          setProduct(response);
          setActiveImageId(response?.productPhotos?.[0]?.id || 0);
        })
        .catch((err) => handleError(err));
    }
  }, [id]);

  useEffect(() => {
    if (cart?.length && product?.id) {
      setProduct(
        (prev) =>
          ({
            ...prev,
            cartQuantity:
              cart?.find((currProd) => currProd?.id === Number(product?.id))
                ?.cartQuantity || 1,
          } as IProduct)
      );
    } else {
      setProduct((prev) => ({ ...prev, cartQuantity: 1 } as IProduct));
    }
    // eslint-disable-next-line
  }, [JSON.stringify(cart), product?.id]);

  return (
    <Row className={"mb-3"}>
      <Col md={1} className={"d-none d-md-flex flex-column"}>
        {product?.productPhotos?.map((photo) => {
          const isActive = photo?.id === activeImageId;
          return (
            <ImageHandler
              className={`product-img-sm cursor-pointer mb-1 ${
                isActive ? "" : "opacity-25"
              }`}
              key={photo?.id}
              onClick={() => setActiveImageId(photo?.id)}
              src={photo?.path}
            />
          );
        })}
      </Col>
      {currentImage?.id && (
        <Col md={5}>
          <ProductFancybox
            product={product as IProduct}
            currentImage={currentImage}
            setActiveImageId={setActiveImageId}
            activeImageId={activeImageId}
          />
        </Col>
      )}
      <ProductInfo
        product={product as IProduct}
        setProduct={
          setProduct as React.Dispatch<React.SetStateAction<IProduct>>
        }
      />
      <Col md={12} className={"mt-5"}>
        <h2 className={"text-uppercase"}>
          {t("general.related")}{" "}
          <span className="fw-bold">{t("general.products")}</span>
        </h2>
      </Col>
      <ProductRelated categoryId={product?.category?.id || 0} />
    </Row>
  );
};

export default SingleProduct;
