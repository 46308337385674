import React, {useState} from 'react';
import {Button, Col, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import ConfirmationModal from '../../../../../shared/components/ConfirmationModal';
import {EOrderStatus} from '../../../../../shared/enums/EOrderStatus';
import {ERole} from '../../../../../shared/enums/ERole';
import {getStatusColor} from '../../../../../shared/functions/getStatusColor';
import {handleError} from '../../../../../shared/functions/handleError';
import {IOrder} from '../../../../../shared/interfaces/IOrder';
import {IUser} from '../../../../../shared/interfaces/IUser';
import {RootState} from '../../../../../store/store';
import {OrdersService} from '../../service';

interface IProps {
    order: IOrder | null
}

const keys = ['firstName', 'lastName', 'phone', 'email', 'country', 'city', 'streetAddress', 'zipCode']
const SingleOrderDetails = ({order}: IProps) => {
    const {t} = useTranslation();
    const {user, status} = order as IOrder;
    const {role} = useSelector((state: RootState) => state.auth.userData) as IUser;
    const isAdmin = role === ERole.ADMIN
    const [isMarkModalOpened, setIsMarkModalOpened] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState<EOrderStatus>(status as EOrderStatus);
    const navigate = useNavigate();
    const handleMarkOrder = (status: EOrderStatus) => {
        setSelectedStatus(status);
        setIsMarkModalOpened(true);
    }
    const onConfirmMarkOrder = async () => {
        !!order?.id && await OrdersService.updateOrderStatus(Number(order?.id), selectedStatus)
            .then(response => {
                if (response?.id) {
                    toast.success(t('general.successfullyUpdated'));
                    navigate(-1);
                    setSelectedStatus(EOrderStatus.PLACED);
                }
            }).catch(err => handleError(err))
    }
    return (
        <>

            <div className={'border border-dark p-3 position-sticky top-100px mb-3'}>
                <h5 className={'mb-3 mt-2'}>
                    {t('general.details')}
                </h5>
                {keys?.map((userKey, index) => {
                    const isLastElement = index === keys?.length - 1
                    return (
                        <Row key={userKey} className={`${isLastElement ? '' : 'border-bottom'} py-2`}>
                            <Col xs={4} className={'ps-0'}>
                                {t(`general.${userKey}`)}
                            </Col>
                            <Col xs={8} className={'text-break'}>
                                {user?.[userKey as keyof typeof user] as string}
                            </Col>
                        </Row>
                    )
                })}
                {isAdmin && status === EOrderStatus.PLACED ?
                    <div className="mt-3 d-flex align-items-center justify-content-between ">
                        <Button variant={getStatusColor(EOrderStatus.CANCELED)}
                                onClick={() => handleMarkOrder(EOrderStatus.CANCELED)}
                                className="rounded-0 text-uppercase flex-grow-1"
                                size={'sm'}>
                            {t('buttons.markAsCanceled')}
                        </Button>
                        <Button variant={getStatusColor(EOrderStatus.DELIVERED)}
                                onClick={() => handleMarkOrder(EOrderStatus.DELIVERED)}
                                className="rounded-0 text-uppercase flex-grow-1 ms-2"
                                size={'sm'}>
                            {t('buttons.markAsDelivered')}
                        </Button>
                    </div> :
                    <Button variant={getStatusColor(status as EOrderStatus)}
                            className="rounded-0 text-uppercase w-100"
                            disabled
                            size={'sm'}>
                        {status}
                    </Button>
                }
            </div>
            <ConfirmationModal title={selectedStatus} handleConfirm={onConfirmMarkOrder}
                               modalTitle={'general.confirmChange'}
                               confirmButtonLabel={'change'}
                               modalDesc={'general.confirmChangeOrder'}
                               show={isMarkModalOpened} handleClose={() => setIsMarkModalOpened(false)}/>
        </>
    );
};

export default SingleOrderDetails;
