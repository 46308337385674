import {EOrderStatus} from '../../../shared/enums/EOrderStatus';
import {ICreateOrder, IOrder} from '../../../shared/interfaces/IOrder';
import {IPagination} from '../../../shared/interfaces/IPagination';
import {requests} from '../../../utils/api/api.service';
import {ADMIN_ORDERS_URL, USER_ORDERS_URL} from '../../../utils/api/endpoints';

export const OrdersService = {
    getAllOrders: (pagination: IPagination): Promise<{ data: IOrder[], count: number, perPage: number }> =>
        requests.get(ADMIN_ORDERS_URL, pagination),

    getOrdersByUserId: (userId: number): Promise<IOrder[]> =>
        requests.get(`${USER_ORDERS_URL}/${userId}`),

    getSingleOrder: (orderId: number, userId: number): Promise<IOrder> => requests.get(`${ADMIN_ORDERS_URL}/${userId}/${orderId}`),

    createOrder: (payload: ICreateOrder[]): Promise<IOrder> =>
        requests.post(ADMIN_ORDERS_URL, {items: payload}),

    updateOrderStatus: (orderId: number, status: EOrderStatus): Promise<IOrder> =>
        requests.patch(`${ADMIN_ORDERS_URL}/${orderId}/status`, {status}),

    deleteOrder: (orderId: number): Promise<{ success: boolean }> =>
        requests.delete(`${ADMIN_ORDERS_URL}/${orderId}`,),

}
