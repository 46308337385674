import React, { useEffect, useState } from "react";
import { useCachingState } from "../../../shared/hooks/useCachingState";
import ShopProduct from "./components/ShopProduct";
import { IProduct } from "../../../shared/interfaces/IProduct";
import { usePagination } from "../../../shared/hooks/usePagination";
import { ProductService } from "../../admin/products/service";
import { handleError } from "../../../shared/functions/handleError";
import ShopFilter from "./components/ShopFilter";
import { EOrderType } from "../../../shared/enums/EOrderType";
import { Spinner } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import { IPagination } from "../../../shared/interfaces/IPagination";
import { useTranslation } from "react-i18next";
import LeadingTitle from "../../../shared/components/LeadingTitle";

export default function Shop() {
  const [shopProducts, setShopProducts] = useCachingState<IProduct[]>(
    "shopProductsList",
    []
  );
  const {
    pagination,
    totalCount,
    setPagination,
    setTotalCount,
    changeFilterHandler,
    setCount,
  } = usePagination({
    perPage: 12,
    paginationKeys: {
      orderType: EOrderType.DESC,
      orderCriteria: "price",
    },
  });
  const [colSize, setColSize] = useState<number>(3);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { t } = useTranslation();

  const fetchMoreData = () => {
    setPagination((prev: IPagination) => ({
      ...prev,
      perPage: prev.perPage + 12,
    }));
  };

  const percentage = Math.ceil((shopProducts?.length / totalCount) * 100);

  useEffect(() => {
    ProductService.getAllProducts(pagination)
      .then((response) => {
        setShopProducts(response?.data);
        setCount(Math.ceil(response.count / response.perPage));
        setTotalCount(response?.count);
      })
      .catch((err) => handleError(err))
      .finally(() => setIsLoading(false));
  }, [pagination, setCount, setTotalCount]);

  return (
    <div>
      <LeadingTitle title={t("navigation.shop")} />
      <div className="container">
        <div className="row">
          <div className="col-12 px-0">
            <ShopFilter
              colSize={colSize}
              setColSize={setColSize}
              changeFilterHandler={changeFilterHandler}
              pagination={pagination}
            />
          </div>
        </div>

        {!isLoading ? (
          !!shopProducts?.length ? (
            <div className="row">
              {shopProducts?.map((shopProduct) => {
                return (
                  <div className={`col-md-${colSize} my-4 ps-0`}>
                    <ShopProduct shopProduct={shopProduct} />
                  </div>
                );
              })}
            </div>
          ) : (
            <div> {t("general.noResults")}</div>
          )
        ) : (
          <div className="loading-screen">
            <Spinner />
            <h4 className="ms-2">Loading...</h4>
          </div>
        )}

        {!!shopProducts?.length && (
          <div className="d-flex justify-content-center align-items-center my-5 flex-column">
            <h6 className="text-uppercase">
              {t("shop.showing")} {shopProducts?.length} {t("shop.of")}{" "}
              {totalCount} {t("shop.items")}
            </h6>
            <div className="w-25 my-3">
              <div
                style={{ width: `${percentage}%` }}
                className="text-end small"
              >
                {percentage}%
              </div>
              <ProgressBar now={percentage} variant="dark" />
            </div>
            {shopProducts?.length !== totalCount && (
              <h6
                className="text-uppercase hover-underline-animation cursor-pointer"
                onClick={fetchMoreData}
              >
                {t("shop.showMore")}
              </h6>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
