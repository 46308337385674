import {TFunction} from 'i18next';
import {toast} from 'react-toastify';

export const handleError = (error: any, t?: TFunction<'translation', undefined>) => {
    const messages = error?.response?.data?.message || error?.message
    const isArray = Array.isArray(messages)
    if (isArray) {
        for (let message of messages) {
            toast.error(t ? t(`error.${message}`) : message)
        }
    }
    return toast.error(t ? t(`error.${messages}`) : messages)
};