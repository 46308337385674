import React, {useEffect, useState} from 'react'
import {IBlog} from '../../../../shared/interfaces/IBlog';
import {useParams} from 'react-router-dom';
import {BlogService} from '../../../admin/blog/service';
import {handleError} from '../../../../shared/functions/handleError';
import ImageHandler from '../../../../shared/components/ImageHandler';
import {formatDate} from '../../../../shared/functions/formatDate';

export default function BlogPost() {
    const {id} = useParams();
    const [currentBlog, setCurrentBlog] = useState<IBlog | null>(null);

    useEffect(() => {
        !!id && BlogService.getBlogById(+id).then(response => {
            setCurrentBlog(response);
        }).catch(err => handleError(err))
    }, [id])

    return (
        <div className="mb-5">
            <div className="text-center">
                <h1> {currentBlog?.title} </h1>
                <div className="text-muted">
                    <small className="me-3"> by admin </small>
                    <small> {formatDate(currentBlog?.createdAt as string)} </small>
                </div>
            </div>

            <ImageHandler src={currentBlog?.path as string} className="w-100 h-400px object-fit-cover my-5"/>
            <div dangerouslySetInnerHTML={{__html: currentBlog?.description as string}}/>
        </div>
    )
}
