import { useSelector } from "react-redux";
import LeadingTitle from "../../../shared/components/LeadingTitle";
import { RootState } from "../../../store/store";
import CartTable from "./components/CartTable";
import { useState } from "react";
import { IUser } from "../../../shared/interfaces/IUser";
import Login from "../../../authentification/login";
import { checkoutSteps } from "../../../utils/data/checkoutSteps";
import Checkout from "./components/Checkout";
import OrderConfirmation from "./components/OrderConfirmation";
import { useTranslation } from "react-i18next";
import { IOrder } from "../../../shared/interfaces/IOrder";
import EmptyCart from "../../../shared/components/EmptyCart";

export default function Cart() {
  const cart = useSelector((state: RootState) => state.cart);
  const [currentStep, setCurrentStep] = useState(1);
  const reduxUser = useSelector((state: RootState) => state.auth.userData);
  const [currentUser, setCurrentUser] = useState(reduxUser || null);
  const { t } = useTranslation();
  const [placedOrder, setPlacedOrder] = useState<IOrder | null>(null);

  const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCurrentUser((prev) => ({ ...prev, [name]: value } as IUser));
  };

  const stepperComponents = {
    1: <CartTable cart={cart} setCurrentStep={setCurrentStep} />,
    2: currentUser?.id ? (
      <Checkout
        changeHandler={changeHandler}
        currentUser={currentUser}
        cart={cart}
        setCurrentStep={setCurrentStep}
        setPlacedOrder={setPlacedOrder}
      />
    ) : (
      <Login />
    ),
    3: <OrderConfirmation placedOrder={placedOrder as IOrder} />,
  };

  const getStepperComponents = (stepId: number) => {
    return (
      stepperComponents[stepId as keyof typeof stepperComponents] ||
      `No component for step ${stepId}!`
    );
  };
  return (
    <>
      <LeadingTitle title={t(checkoutSteps[currentStep - 1]?.title)} />

      <div className="container mb-5">
        {cart?.length || currentStep === 3 ? (
          <>
            <div className="row border-bottom mt-5">
              {checkoutSteps?.map((step) => {
                return (
                  <div
                    key={step?.id}
                    onClick={() => {
                      if (step?.id <= currentStep) {
                        setCurrentStep(step?.id);
                      }
                    }}
                    className={`col-md-4 ps-0 border-bottom 
                            border-${
                              step?.id <= currentStep
                                ? "dark cursor-pointer"
                                : "secondary opacity-50 cursor-not-allowed"
                            }`}
                  >
                    <h5 className="text-uppercase">
                      {" "}
                      0{step?.id} {t(step?.name)}
                    </h5>
                    <small className="text-muted ms-4">
                      {t(step?.description)}
                    </small>
                  </div>
                );
              })}
            </div>
            <div className="my-3">{getStepperComponents(currentStep)}</div>
          </>
        ) : (
          <div className={"my-3"}>
            <EmptyCart />
          </div>
        )}
      </div>
    </>
  );
}
