import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import Breadcrumbs from '../../../shared/components/Breadcrumbs';
import ConfirmationModal from '../../../shared/components/ConfirmationModal';
import Pagination from '../../../shared/components/Pagination';
import SharedTable from '../../../shared/components/SharedTable';
import {EOrderType} from '../../../shared/enums/EOrderType';
import {handleError} from '../../../shared/functions/handleError';
import {useCachingState} from '../../../shared/hooks/useCachingState';
import {usePagination} from '../../../shared/hooks/usePagination';
import ProductsFilter from './components/ProductsFilter';
import {ProductService} from './service';
import {productsTableHeaders} from './table/productsTableHeaders';
import {IProduct} from '../../../shared/interfaces/IProduct';

export default function AdminProducts() {
    const [products, setProducts] = useCachingState<IProduct[]>('productsList', []);
    const [selectedProduct, setSelectedProduct] = useState<IProduct | null>(null);
    const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
    const {
        pagination,
        count,
        setCount,
        changeFilterHandler,
        totalCount,
        setTotalCount,
    } = usePagination({
        perPage: 10,
        paginationKeys: {
            orderType: EOrderType.DESC,
        },
    });

    const navigate = useNavigate();
    const {t} = useTranslation();

    const onDeleteClose = () => {
        setIsDeleteOpen(false);
        setSelectedProduct(null);
    };

    const handleDelete = () => {
        selectedProduct?.id &&
        ProductService.deleteProduct(+selectedProduct?.id)
            .then((response) => {
                if (response) {
                    toast.success(t('general.successfullyDeleted'));
                    setIsDeleteOpen(false);
                    setProducts((prev) =>
                        prev.filter((product) => selectedProduct?.id !== product?.id)
                    );
                }
            })
            .catch((err) => handleError(err));
    };

    useEffect(() => {
        ProductService.getAllProducts(pagination)
            .then((response) => {
                setProducts(response?.data);
                setCount(Math.ceil(response.count / response.perPage));
                setTotalCount(response?.count);
            })
            .catch((err) => handleError(err));
    }, [pagination, setCount, setTotalCount]);

    return (
        <div>
            <div className="mb-3">
                <Breadcrumbs/>
            </div>

            <ProductsFilter
                changeFilterHandler={changeFilterHandler}
                pagination={pagination}
            />

            <SharedTable
                data={products}
                headers={productsTableHeaders}
                ctaHeaders={['actions']}
                ctaButtons={['view', 'delete']}
                emptyState={
                    <div className={'text-center'}>{t('general.listEmpty')}</div>
                }
                onButtonClick={async (btnName, data) => {
                    if (btnName === 'delete') {
                        setSelectedProduct(data);
                        setIsDeleteOpen(true);
                    }
                    if (btnName === 'view') {
                        navigate(`/admin/products/edit/${data?.id}`);
                    }
                }}
            />

            <Pagination
                currentPage={pagination?.page}
                totalPages={count}
                totalItems={totalCount}
                onPerPageClick={(newPerPage) =>
                    changeFilterHandler('perPage', newPerPage)
                }
                onPageClick={(newPage) => changeFilterHandler('page', newPage)}
            />

            <ConfirmationModal
                show={isDeleteOpen}
                handleClose={onDeleteClose}
                handleConfirm={handleDelete}
                title={String(selectedProduct?.name)}
            />
        </div>
    );
}
