import React, { FormEvent, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LoginForm from './components/LoginForm';
import { ILogin } from '../../shared/interfaces/ILogin';
import { AuthService } from './service';
import { handleError } from '../../shared/functions/handleError';
import authSlice from '../../store/slices/auth.slice';
import { useDispatch, useSelector } from 'react-redux';
import { getInitialRoute } from '../../shared/functions/getInitialRoute';
import { toast } from 'react-toastify';
import { RootState } from '../../store/store';

const initialState = {
    email: '',
    password: '',
}

const Login = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isAuthenticated, userData } = useSelector((state: RootState) => state.auth);


    const [loginData, setLoginData] = useState<ILogin>(initialState);

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setLoginData((prev) => ({ ...prev, [name]: value }));
    }

    const onSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        await AuthService.loginHandler(loginData).then(response => {
            localStorage.setItem('token', JSON.stringify(response?.token));

            dispatch(authSlice.actions.setLoginState({
                isAuthenticated: true,
                userData: response?.userData
            }));
            navigate(getInitialRoute(response?.userData?.role));
            toast.success("Welcome back!");
        }).catch(err => {
            handleError(err, t);
        });
    }

    useEffect(() => {
        if (isAuthenticated && userData?.role) {
            navigate(getInitialRoute(userData?.role));
        }
    }, [isAuthenticated, userData?.role, navigate])

    return (
        <div className='d-flex flex-column align-items-center justify-content-center min-h-50vh'>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <Form onSubmit={onSubmit}>
                            <LoginForm changeHandler={changeHandler} />
                        </Form>
                        <div className="d-flex justify-content-center mt-3">
                            <small className="text-muted"> {t("login.noAccount")}
                                <span className='text-decoration-underline text-dark cursor-pointer ms-2'
                                    onClick={() => navigate("/register")}>
                                    {t("login.createAccount")}
                                </span>
                            </small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
