import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Autoplay, Navigation} from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
// Import Swiper React components
import {Swiper, SwiperSlide} from 'swiper/react';
import {handleError} from '../../../../shared/functions/handleError';
import {useCachingState} from '../../../../shared/hooks/useCachingState';
import {usePagination} from '../../../../shared/hooks/usePagination';
import {IBlog} from '../../../../shared/interfaces/IBlog';
import {BlogService} from '../../../admin/blog/service';
import BlogCard from '../../blog/components/BlogCard';

export default function LatestNews() {
    const [blogs, setBlogs] = useCachingState<IBlog[]>('blogsListUser', []);
    const {t} = useTranslation();
    const {pagination} = usePagination({
        perPage: 5,
    });

    useEffect(() => {
        BlogService.getAllBlogPosts(pagination)
            .then((response) => {
                setBlogs(response?.data);
            })
            .catch((err) => handleError(err));
    }, [pagination]);

    return (
        <>
            {!!blogs?.length && <div className="mb-5">
                <div className="d-flex flex-column justify-content-center align-items-center mt-5 mb-3">
                    <h2 className="text-uppercase"> {t('general.latestNews')} </h2>
                </div>

                <Swiper
                    spaceBetween={20}
                    slidesPerView={4}
                    navigation={true}
                    breakpoints={{
                        0: {
                            slidesPerView: 1,
                        },
                        400: {
                            slidesPerView: 1,
                        },
                        639: {
                            slidesPerView: 3,
                        },
                        865: {
                            slidesPerView: 4,
                        },
                    }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    modules={[Autoplay, Navigation]}
                >
                    {blogs?.map((blog) => {
                        return (
                            <SwiperSlide key={blog?.id}>
                                <BlogCard blog={blog}/>
                            </SwiperSlide>
                        );
                    })}
                </Swiper>
            </div>}
        </>
    );
}
