import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { IBlog } from '../../../../shared/interfaces/IBlog';
import BlogForm from './BlogForm';
import { BlogService } from '../service';
import { handleError } from '../../../../shared/functions/handleError';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { multipartFormData } from '../../../../utils/api/api.service';
import { ADMIN_BLOG } from '../../../../utils/api/endpoints';

export default function SingleBlogPost() {
    const { id } = useParams();
    const [currentBlog, setCurrentBlog] = useState<IBlog | null>(null);
    const { t } = useTranslation();
    const [file, setFile] = useState<File | null>(null);

    const navigate = useNavigate();

    const handleChange = (name: string, value: string) => {
        setCurrentBlog(prev => ({ ...prev, [name]: value }) as IBlog)
    }

    const handleSubmit = () => {
        !!id && multipartFormData(file, `${ADMIN_BLOG}/${id}`,
            {
                title: currentBlog?.title,
                description: currentBlog?.description,
                shortDescription: currentBlog?.shortDescription
            }, 'put', 'file').then((response) => {
                if (response) {
                    toast.success(t("general.successfullyUpdated"));
                    navigate(-1);
                    setCurrentBlog(null);
                }
            }).catch(err => handleError(err))
    }

    useEffect(() => {
        id && BlogService.getBlogById(+id).then((response) => {
            setCurrentBlog(response);
        }).catch(err => handleError(err));
    }, [id]);

    return (
        <Form
            onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
            }}>
            <BlogForm handleChange={handleChange} currentBlog={currentBlog} file={file}
                setFile={setFile} />
        </Form>

    )
}
