import React from 'react';
import ImageHandler from '../../../../shared/components/ImageHandler';
import {scrollTo} from '../../../../shared/functions/scrollTo';
import {IBlog} from '../../../../shared/interfaces/IBlog';
import {formatDate} from '../../../../shared/functions/formatDate';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

interface IProps {
    blog: IBlog;
}

export default function BlogCard({blog}: IProps) {
    const navigate = useNavigate();
    const {t} = useTranslation();

    return (
        <div
            className="cursor-pointer"
            onClick={() => {
                navigate(`/blog/${blog?.id}`);
                scrollTo();
            }}
        >
            <ImageHandler
                src={blog?.path}
                className="w-100 h-400px object-fit-cover"
            />
            <div className="text-muted d-flex justify-content-between my-2">
                <small> by admin </small>
                <small> {formatDate(blog?.createdAt as string)}. </small>
            </div>

            <h5 className="text-uppercase mb-2 text-truncate"> {blog.title} </h5>

            <p className="text-muted mb-1 text-truncate">
                {' '}
                {blog?.shortDescription}{' '}
            </p>
            <span className="hover-underline-animation text-uppercase cursor-pointer">
        {' '}
                {t('general.readMore')}{' '}
      </span>
        </div>
    );
}
