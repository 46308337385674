import React, { SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RiSortAsc, RiSortDesc } from "react-icons/ri";
import FilterDrawer from "../../../../shared/components/FilterDrawer";
import Search from "../../../../shared/components/Search";
import SelectDropdown from "../../../../shared/components/SelectDropdown";
import { EOrderType } from "../../../../shared/enums/EOrderType";
import { handleError } from "../../../../shared/functions/handleError";
import { usePagination } from "../../../../shared/hooks/usePagination";
import { ICategory } from "../../../../shared/interfaces/ICategory";
import { CategoryService } from "../../../admin/categories/service";
import { EProductSortingCriteria } from "../../../../shared/enums/ESortingCriteria";

interface IProps {
  colSize: number;
  setColSize: React.Dispatch<SetStateAction<number>>;
  changeFilterHandler: (
    name: string,
    value: string | number | boolean | null
  ) => void;
  pagination: any;
}

const productsView = [
  { id: 1, userSize: 2, colSize: 6 },
  { id: 2, userSize: 3, colSize: 4 },
  { id: 3, userSize: 4, colSize: 3 },
];

const searchKeys = ["title"];

const ShopFilter = ({
  colSize,
  setColSize,
  changeFilterHandler,
  pagination,
}: IProps) => {
  const { t } = useTranslation();
  const [showFilter, setShowFilter] = useState(false);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const { pagination: categoriesPagination } = usePagination({ perPage: 0 });

  useEffect(() => {
    CategoryService.getAllCategories(categoriesPagination)
      .then((response) => setCategories(response?.data))
      .catch((err) => handleError(err));
  }, [categoriesPagination]);

  return (
    <>
      <div
        className={
          "d-flex align-items-center justify-content-between mb-3 ps-0 flex-column flex-md-row"
        }
      >
        <div className="d-none d-md-flex align-items-center mb-3 mb-md-0">
          <h6 className="text-uppercase me-2"> {t("buttons.view")} </h6>
          {productsView?.map((view) => {
            return (
              <h6
                key={view?.id}
                onClick={() => setColSize(view.colSize)}
                className={`${
                  colSize === view.colSize && "text-decoration-underline"
                } cursor-pointer me-2`}
              >
                {view.userSize}
              </h6>
            );
          })}
        </div>

        <div className="d-flex align-items-center justify-content-end">
          <div className=" px-3">
            <h6
              className={
                "text-uppercase cursor-pointer d-flex align-items-center"
              }
              onClick={() => {
                changeFilterHandler(
                  "orderType",
                  pagination?.orderType === EOrderType.ASC
                    ? EOrderType.DESC
                    : EOrderType.ASC
                );
              }}
            >
              {pagination?.orderType}
              {pagination?.orderType === EOrderType.ASC ? (
                <RiSortAsc className={"ms-1"} />
              ) : (
                <RiSortDesc className={"ms-1"} />
              )}
            </h6>
          </div>

          <div className="border-start border-end ps-3">
            <SelectDropdown
              name={"orderCriteria"}
              onChange={(event) => {
                changeFilterHandler("orderCriteria", event?.target?.value);
              }}
              options={Object.values(EProductSortingCriteria).map(
                (criteria) => ({
                  value: criteria,
                  name: t(`general.${criteria}`),
                })
              )}
            />
          </div>

          <h6
            className={
              "text-uppercase cursor-pointer hover-underline-animation ms-3"
            }
            onClick={() => setShowFilter(true)}
          >
            {t("general.filters")}
          </h6>
        </div>
      </div>

      <FilterDrawer show={showFilter} handleClose={() => setShowFilter(false)}>
        <>
          <div className="my-3">
            <SelectDropdown
              name={"categoryId"}
              defaultLabel={t("general.filterByCategory")}
              onChange={(event) => {
                changeFilterHandler("categoryId", event?.target?.value);
              }}
              options={categories?.map((category) => ({
                value: category?.id,
                name: category?.name,
              }))}
            />
          </div>
          <Search changeFilterHandler={changeFilterHandler} keys={searchKeys} />
        </>
      </FilterDrawer>
    </>
  );
};

export default ShopFilter;
