import React, { SetStateAction } from "react";
import { Button, Col, Row } from "react-bootstrap";
import ImageHandler from "../../../../shared/components/ImageHandler";
import { useTranslation } from "react-i18next";
import { formatPrice } from "../../../../shared/functions/formatPrice";
import { IProduct } from "../../../../shared/interfaces/IProduct";
import { useDispatch } from "react-redux";
import cartSlice from "../../../../store/slices/cart.slice";
import { IoMdClose } from "react-icons/io";

interface IProps {
  cart: IProduct[];
  setCurrentStep: React.Dispatch<SetStateAction<number>>;
}

export default function CartTable({ cart, setCurrentStep }: IProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onIncrement = (productId: number) => {
    const updatedList = cart?.map((product) => {
      if (productId === product?.id) {
        return {
          ...product,
          cartQuantity: Number(product?.cartQuantity) + 1,
        };
      }
      return product;
    });
    localStorage.setItem("cart", JSON.stringify(updatedList));
    dispatch(cartSlice.actions.setCart(updatedList));
  };

  const onDecrement = (productId: number) => {
    const currentProduct = cart?.find((product) => product?.id === productId);
    if (currentProduct?.cartQuantity === 1) {
      return;
    }
    const updatedList = cart?.map((product) => {
      if (productId === product?.id) {
        return {
          ...product,
          cartQuantity: Number(product?.cartQuantity) - 1,
        };
      }
      return product;
    });
    localStorage.setItem("cart", JSON.stringify(updatedList));
    dispatch(cartSlice.actions.setCart(updatedList));
  };

  const handleRemoveFromCart = (productId: number) => {
    const updatedCart = cart.filter((item) => item.id !== productId);
    dispatch(cartSlice.actions.setCart(updatedCart));
    localStorage.setItem("cart", JSON.stringify(updatedCart));
  };
  return (
    <>
      <Row className={"border-bottom py-2 mb-3 mt-5"}>
        <Col xs={6}>
          <h6 className={"text-uppercase"}>{t("navigation.products")}</h6>
        </Col>
        <Col xs={2} className={"px-0"}>
          <h6 className={"text-uppercase"}>{t("general.price")}</h6>
        </Col>
        <Col xs={2} className={"px-0"}>
          <h6 className={"text-uppercase"}>{t("general.quantity")}</h6>
        </Col>
        <Col xs={2} className={"px-0"}>
          <h6 className={"text-uppercase"}>{t("general.subtotal")}</h6>
        </Col>
      </Row>

      {cart?.length ? (
        cart?.map((item) => {
          return (
            <Row key={item?.id} className={`pb-3 mb-3 border-bottom`}>
              <Col xs={6} className={"ps-0"}>
                <Row>
                  <Col md={4} className={"ps-0"}>
                    <ImageHandler
                      className={"product-order-img default-radius"}
                      src={item?.productPhotos?.[0]?.path}
                    />
                  </Col>
                  <Col
                    md={4}
                    className={"d-flex justify-content-center flex-column"}
                  >
                    <p className="mb-0 fw-bold">{item?.name || "-"}</p>

                    <p className="mb-0 text-muted">
                      {t("general.description")}: {item?.name || "-"}
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col xs={2} className={"ps-0 d-flex align-items-center"}>
                <p className="mb-0 text-muted">
                  {formatPrice(item?.price || 0)}
                </p>
              </Col>
              <Col xs={2} className={"ps-0 d-flex align-items-center"}>
                <div className="d-flex align-items-center justify-content-center border border-2 p-1">
                  <button
                    className="cart-btn"
                    onClick={() => onDecrement(item?.id)}
                    disabled={item?.cartQuantity === 1}
                  >
                    -
                  </button>
                  <p
                    className="mb-0 text-muted d-flex justify-content-center"
                    style={{ width: 15 }}
                  >
                    {item?.cartQuantity}
                  </p>
                  <button
                    className="cart-btn"
                    disabled={item?.quantity <= Number(item?.cartQuantity)}
                    onClick={() => onIncrement(item?.id)}
                  >
                    +
                  </button>
                </div>
              </Col>
              <Col xs={1} className={"ps-0 d-flex align-items-center"}>
                <p className="mb-0 fw-bold">
                  {formatPrice((item?.price || 0) * Number(item?.cartQuantity))}
                </p>
              </Col>
              <Col
                xs={1}
                className={
                  "d-flex justify-content-center align-items-center cursor-pointer"
                }
                onClick={() => handleRemoveFromCart(item?.id)}
              >
                <IoMdClose />
              </Col>
            </Row>
          );
        })
      ) : (
        <div className={"ms-2"}>{t("general.listEmpty")}</div>
      )}

      {!!cart?.length && (
        <Row>
          <Col md={12} className={"d-flex justify-content-end mb-3 px-0"}>
            <Button
              variant="dark"
              className={"p-3 text-uppercase"}
              onClick={() => setCurrentStep((prev) => prev + 1)}
            >
              {" "}
              {t("buttons.proceedToCheckout")}{" "}
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
}
