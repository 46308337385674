import React from 'react'
import {Button, Modal} from 'react-bootstrap'
import {useTranslation} from 'react-i18next'

interface IProps {
    show: boolean,
    handleClose: () => void,
    handleConfirm: () => void,
    title: string
    modalTitle?: string
    modalDesc?: string
    confirmButtonLabel?: string
}

export default function ConfirmationModal({
                                              show,
                                              handleClose,
                                              title,
                                              handleConfirm,
                                              modalTitle = 'general.confirmDelete',
                                              modalDesc = 'general.confirmDeleteDesc',
                                              confirmButtonLabel = 'delete',
                                          }: IProps) {
    const {t} = useTranslation();

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>{t(modalTitle)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{t(modalDesc, {name: title})}</Modal.Body>
            <Modal.Footer>
                <Button variant="dark" className="text-white" size="sm" onClick={handleClose}>
                    {t('buttons.cancel')}
                </Button>
                <Button variant="primary" className="text-white" size="sm" onClick={handleConfirm}>
                    {t(`buttons.${confirmButtonLabel}`)}
                </Button>
            </Modal.Footer>
        </Modal>)
}
