import {SetStateAction} from 'react';
import {Button} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {useNavigate} from 'react-router-dom';
import Fancybox from '../../../../shared/components/Fancybox';
import ImageHandler from '../../../../shared/components/ImageHandler';
import {IBlog} from '../../../../shared/interfaces/IBlog';

interface IProps {
    handleChange: (name: string, value: string) => void;
    currentBlog: IBlog | null,
    file: File | null,
    setFile: React.Dispatch<SetStateAction<File | null>>,
}

export default function BlogForm({handleChange, currentBlog, file, setFile}: IProps) {
    const navigate = useNavigate();
    const {t} = useTranslation();

    return (
        <>
            <div className="border-bottom border-secondary mb-4">
                <h4> {!currentBlog?.id ? t('buttons.create') : t('buttons.update')} blog </h4>
            </div>
            <div>
                <label htmlFor="title"> {t('blog.title')} </label>
                <input type="text"
                       className="form-control rounded-0 p-3"
                       name="title"
                       id="title"
                       required
                       placeholder="Title"
                       value={currentBlog?.title}
                       onChange={(e) => {
                           handleChange(e.target.name, e.target.value)
                       }}/>
            </div>

            <div className="my-3">
                <label htmlFor="shortDesc"> {t('blog.shortDescription')} </label>
                <textarea name="shortDescription"
                          value={currentBlog?.shortDescription || ''}
                          onChange={(e) => {
                              handleChange(e.target.name, e.target.value);
                          }}
                          placeholder="Short description"
                          rows={6}
                          className="w-100 form-control rounded-0 p-3"
                          id="shortDesc"
                          required
                />
            </div>


            <div>
                <div className="mb-3">
                    <label> {t('blog.photo')} </label>

                    <input type="file"
                           name="path"
                           className="form-control p-3 rounded-0"
                           onChange={(e) => setFile(e?.target?.files?.[0] || null)}
                           accept="image/*"
                           required/>

                    {file &&
                        <ImageHandler
                            className="object-fit-cover w-100 cursor-pointer h-400px p-2"
                            src={URL.createObjectURL(file)}
                        />
                    }
                </div>

                {currentBlog?.id && <Fancybox
                    options={{
                        Carousel: {
                            infinite: false,
                        },
                    }}>
                    <a data-fancybox="gallery" href={currentBlog?.path}>
                        <ImageHandler src={currentBlog?.path as string}
                                      className={'cursor-pointer w-100 h-400px object-fit-cover'}/>
                    </a>
                </Fancybox>}
            </div>

            <div className="mt-3">
                <label> {t('blog.postContent')} </label>
                <ReactQuill theme="snow" value={currentBlog?.description} onChange={(value) => {
                    handleChange('description', value)
                }} modules={{
                    toolbar: [
                        [{'header': '1'}, {'header': '2'}, {'font': []}],
                        [{'list': 'ordered'}, {'list': 'bullet'}],
                        ['bold', 'italic', 'underline'],
                        ['link', 'image'],
                        [{'align': []}],
                        ['clean']
                    ]
                }} className="mb-3"/>
            </div>

            <div className="d-flex justify-content-end mb-4">
                <Button variant="dark" onClick={() => navigate(-1)}>
                    Cancel
                </Button>

                <Button variant="primary" className="ms-2" type="submit">
                    {currentBlog?.id ? 'Update' : 'Create'}
                </Button>
            </div>

        </>

    )
}
