import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { IOrder } from '../../../interfaces/IOrder';
import { Col, Row } from 'react-bootstrap';
import SingleOrderTable from '../../../../app/admin/orders/single-order/components/SingleOrderTable';
import SingleOrderDetails from '../../../../app/admin/orders/single-order/components/SingleOrderDetails';
import { handleError } from '../../../functions/handleError';
import { OrdersService } from '../../../../app/admin/orders/service';
import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import { IUser } from '../../../interfaces/IUser';


export default function SingleUserOrder() {
    const { id: orderId } = useParams();
    const [order, setOrder] = useState<IOrder | null>(null)
    const { id: userId } = useSelector((state: RootState) => state.auth.userData) as IUser;

    useEffect(() => {
        if (userId && orderId) {
            OrdersService.getSingleOrder(+orderId, userId)
                .then(response => {
                    setOrder(response)
                }).catch(err => handleError(err))
        }
    }, [orderId, userId])

    return (
        <>
            {order?.id && <Row>
                <Col md={8}>
                    <SingleOrderTable order={order} />
                </Col>
                <Col md={4}>
                    <SingleOrderDetails order={order} />
                </Col>
            </Row>}
        </>
    )
}
