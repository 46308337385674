import React from "react";
import { useLocation } from "react-router-dom";

const Breadcrumbs = () => {
  const { pathname } = useLocation();
  const breadcrumbs = pathname?.split("/");
  return (
    <div className={"d-flex align-items-center"}>
      {breadcrumbs?.map((breadcrumb, index) => {
        const showLine = index !== breadcrumbs?.length - 1 && index !== 0;
        return (
          <div key={breadcrumb}>
            <h6
              className={`text-uppercase hover-underline-animation cursor-pointer`}
            >
              {breadcrumb}
            </h6>
            {showLine && <span className={"mx-1"}>/</span>}
          </div>
        );
      })}
    </div>
  );
};

export default Breadcrumbs;
