import React from 'react';
import Footer from './Footer';
import Header from './Header';
import {useLocation} from 'react-router-dom';

interface IProps {
    children: JSX.Element;
}

const Layout = ({children}: IProps) => {
    const {pathname} = useLocation();
    const routesWithoutContainer = ['/', '/blog', '/shop', '/about', '/faq', '/contact', '/users/wishlist', '/users/cart', '/price-policy', '/return-policy']

    return (
        <div className="layout-container">
            <Header/>
            <div className={`${routesWithoutContainer?.includes(pathname) ? '' : 'container mt-100px '} px-0`}>
                {children}
            </div>
            <Footer/>
        </div>
    );
};

export default Layout;
