import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import RegisterForm from './components/RegisterForm';
import { IUser } from '../../shared/interfaces/IUser';
import { AuthService } from '../login/service';
import { handleError } from '../../shared/functions/handleError';
import { toast } from 'react-toastify';


const Register = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [currentUser, setCurrentUser] = useState<IUser | null>(null);

    const onSubmit = () => {
        AuthService.registerHandler(currentUser as IUser).then(response => {
            toast.success(t("general.successfullyRegistered"));
            navigate("/login");
        }).catch(err => handleError(err));
    }

    const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setCurrentUser(prev => ({ ...prev, [name]: value }) as IUser);
    }

    return (
        <div className='d-flex flex-column align-items-center justify-content-center min-h-50vh'>
            <div className="container">
                <div className="row justify-content-center mb-5">
                    <div className="col-md-6">
                        <Form
                            onSubmit={(e) => {
                                e.preventDefault();
                                onSubmit();
                            }}>
                            <RegisterForm changeHandler={changeHandler} currentUser={currentUser} />
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;
