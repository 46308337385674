import React from "react";
import ImageHandler from "../../../../shared/components/ImageHandler";
import { useDispatch, useSelector } from "react-redux";
import { formatPrice } from "../../../../shared/functions/formatPrice";
import { RootState } from "../../../../store/store";
import { IProduct } from "../../../../shared/interfaces/IProduct";
import cartSlice from "../../../../store/slices/cart.slice";
import { useNavigate } from "react-router-dom";
import { scrollTo } from "../../../../shared/functions/scrollTo";
import { useTranslation } from "react-i18next";

interface IProps {
  product: IProduct;
}

export default function ProductCard({ product }: IProps) {
  const cart = useSelector((state: RootState) => state.cart);
  const isInCart = cart.some((item) => item?.id === product?.id);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleAddToCart = (productId: number, isInList = false) => {
    if (isInList) {
      const updatedCart = cart.filter((item) => item.id !== productId);
      dispatch(cartSlice.actions.setCart(updatedCart));
      localStorage.setItem("cart", JSON.stringify(updatedCart));
    } else {
      const updatedCart = cart.concat({
        ...product,
        cartQuantity: 1,
      } as IProduct);
      dispatch(cartSlice.actions.setCart(updatedCart));
      localStorage.setItem("cart", JSON.stringify(updatedCart));
    }
  };

  return (
    <div className="product-card">
      <div
        className="position-relative cursor-pointer"
        onClick={() => {
          navigate(`/product/${product?.id}`);
          scrollTo();
        }}
      >
        <ImageHandler
          src={product.productPhotos?.[0]?.path}
          className="w-100 h-300px object-fit-cover"
        />
        <button
          className="add-to-cart-button"
          onClick={(e) => {
            e.stopPropagation();
            handleAddToCart(Number(product?.id), isInCart);
          }}
        >
          {isInCart ? t("buttons.alreadyAdded") : t("buttons.addToCart")}
        </button>
      </div>
      <div className="d-flex flex-column justify-content-center align-items-center">
        <small className="text-muted my-2"> {product.category?.name} </small>
        <h5 className="mb-2"> {product.name} </h5>
        <h5> {formatPrice(product.price)} </h5>
      </div>
    </div>
  );
}
