import React, {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import ConfirmationModal from '../../../shared/components/ConfirmationModal';
import Pagination from '../../../shared/components/Pagination';
import SharedTable from '../../../shared/components/SharedTable';
import {useCachingState} from '../../../shared/hooks/useCachingState';
import {IOrder} from '../../../shared/interfaces/IOrder';
import {usePagination} from '../../../shared/hooks/usePagination';
import OrderFilter from './components/OrderFilter';
import {OrdersService} from './service';
import {handleError} from '../../../shared/functions/handleError';
import {orderTableHeader} from './table/orderTableHeader';
import {useTranslation} from 'react-i18next';

export default function AdminOrders() {
    const navigate = useNavigate();
    const [orders, setOrders] = useCachingState<IOrder[]>('ordersList', []);
    const {t} = useTranslation();
    const [isDeleteModalOpened, setIsDeleteModalOpened] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState<IOrder | null>(null)
    const {
        pagination,
        count,
        setCount,
        changeFilterHandler,
        totalCount,
        setTotalCount,
    } = usePagination({
        perPage: 10,
        paginationKeys: {
            orderType: 'DESC',
        },
    });

    const onDeleteClick = (selected: IOrder) => {
        setIsDeleteModalOpened(true);
        setSelectedOrder(selected)
    };

    const handleDeleteOrder = async () => {
        selectedOrder?.id && await OrdersService.deleteOrder(selectedOrder?.id)
            .then(response => {
                if (response?.success) {
                    setSelectedOrder(null);
                    toast.success(t('general.successfullyDeleted'));
                    setIsDeleteModalOpened(false);
                    changeFilterHandler('updatedAt', new Date()?.getTime())
                }
            }).catch(err => handleError(err))
    }

    useEffect(() => {
        OrdersService.getAllOrders(pagination)
            .then((response) => {
                setOrders(response?.data);
                setCount(Math.ceil(response.count / response.perPage));
                setTotalCount(response?.count);
            })
            .catch((err) => handleError(err));
    }, [pagination, setCount, setTotalCount]);

    return (
        <div>
            <OrderFilter
                changeFilterHandler={changeFilterHandler}
                orderPagination={pagination}
            />
            <SharedTable
                data={orders}
                headers={orderTableHeader}
                ctaHeaders={['actions']}
                ctaButtons={['view', 'delete']}
                emptyState={
                    <div className={'text-center'}>{t('general.listEmpty')}</div>
                }
                onButtonClick={async (btnName, data) => {
                    if (btnName === 'delete') {
                        onDeleteClick(data);
                        return
                    }
                    if (btnName === 'view') {
                        navigate(`/admin/orders/edit/${data?.id}`);
                    }
                }}
            />
            <Pagination
                currentPage={pagination?.page}
                totalPages={count}
                totalItems={totalCount}
                onPerPageClick={(newPerPage) =>
                    changeFilterHandler('perPage', newPerPage)
                }
                onPageClick={(newPage) => changeFilterHandler('page', newPage)}
            />
            <ConfirmationModal show={isDeleteModalOpened} handleClose={() => {
                setIsDeleteModalOpened(false);
                setSelectedOrder(null);
            }} handleConfirm={handleDeleteOrder} title={`ID: ${selectedOrder?.id}`}/>
        </div>
    );
}
