import React from 'react';
import {Button, Col} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {FaHeart, FaRegHeart} from 'react-icons/fa';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import Breadcrumbs from '../../../../shared/components/Breadcrumbs';
import {formatPrice} from '../../../../shared/functions/formatPrice';
import {IProduct} from '../../../../shared/interfaces/IProduct';
import cartSlice from '../../../../store/slices/cart.slice';
import wishlistSlice from '../../../../store/slices/wishlist.slice';
import {RootState} from '../../../../store/store';
import {toast} from 'react-toastify';

interface IProps {
    setProduct: React.Dispatch<React.SetStateAction<IProduct>>;
    product: IProduct;
}

const ProductInfo = ({product, setProduct}: IProps) => {
    const {id} = useParams();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const wishlist = useSelector((state: RootState) => state.wishlist);
    const cart = useSelector((state: RootState) => state.cart);
    const isProductInCart = cart?.some((product) => product?.id === Number(id));
    const isWishlist = wishlist.some((product) => product?.id === Number(id));

    const onIncrement = (productId: number) => {
        if (product.quantity < Number(product?.cartQuantity)) {
            toast.warning('no qunatity');
            return;
        }
        setProduct((prev) => ({
            ...prev,
            cartQuantity: Number(prev?.cartQuantity) + 1,
        }));
    };

    const onDecrement = (productId: number) => {
        if (1 > Number(product?.cartQuantity)) {
            toast.warning('Min 1');
            return;
        }
        setProduct((prev) => ({
            ...prev,
            cartQuantity: Number(prev?.cartQuantity) - 1,
        }));
    };

    const handleAddToCart = (product: IProduct, isInCart = false) => {
        if (isInCart) {
            const updatedList = cart.map((item) => {
                if (item.id === product?.id) {
                    return {
                        ...item,
                        cartQuantity: product?.cartQuantity,
                    };
                }
                return item;
            });
            dispatch(cartSlice.actions.setCart(updatedList));
            localStorage.setItem('cart', JSON.stringify(updatedList));
        } else {
            const updatedList = cart.concat(product as IProduct);
            dispatch(cartSlice.actions.setCart(updatedList));
            localStorage.setItem('cart', JSON.stringify(updatedList));
        }
    };

    const handleWishlistClick = (productId: number, isInList = false) => {
        if (isInList) {
            const updatedList = wishlist.filter((item) => item.id !== productId);
            dispatch(wishlistSlice.actions.setWishlist(updatedList));
            localStorage.setItem('wishlist', JSON.stringify(updatedList));
        } else {
            const updatedList = wishlist.concat({
                ...product,
                cartQuantity: 1,
            } as IProduct);
            dispatch(wishlistSlice.actions.setWishlist(updatedList));
            localStorage.setItem('wishlist', JSON.stringify(updatedList));
        }
    };
    return (
        <>
            <Col md={5} className={'d-flex flex-column  justify-content-center'}>
                <Breadcrumbs/>
                <h2 className={'mt-md-5'}>{product?.name}</h2>
                <h5 className={'mt-3'}>{formatPrice(product?.price as number)}</h5>
                <p className={'mt-md-5'}>{product?.description}</p>
                <div className="d-flex align-items-center">
                    <div className="d-flex align-items-center justify-content-center border border-2 p-1 w-25">
                        <button
                            className="cart-btn"
                            onClick={() => onDecrement(Number(product?.id))}
                            disabled={product?.cartQuantity === 1}
                        >
                            -
                        </button>
                        <p
                            className="mb-0 text-muted d-flex justify-content-center"
                            style={{width: 15}}
                        >
                            {product?.cartQuantity}
                        </p>
                        <button
                            className="cart-btn"
                            disabled={
                                Number(product?.quantity) <= Number(product?.cartQuantity)
                            }
                            onClick={() => onIncrement(Number(product?.id))}
                        >
                            +
                        </button>
                    </div>
                    <Button
                        variant={'dark'}
                        onClick={() => handleAddToCart(product, isProductInCart)}
                        className="text-uppercase p-3 flex-grow-1 ms-2"
                    >
                        {t(isProductInCart ? 'buttons.alreadyAdded' : 'buttons.addToCart')}
                    </Button>
                </div>
                <div className={'mt-3'}>
                    <div
                        className="hover-underline-animation cursor-pointer"
                        onClick={() => handleWishlistClick(Number(product?.id), isWishlist)}
                    >
                        {isWishlist ? (
                            <FaHeart className="text-primary cursor-pointer"/>
                        ) : (
                            <FaRegHeart className="cursor-pointer text-muted"/>
                        )}
                        <span className={`ms-1 ${isWishlist ? 'text-primary' : ''}`}>
              {!isWishlist
                  ? t('wishlist.addToWishlist')
                  : t('wishlist.removeFromWishlist')}
            </span>
                    </div>
                </div>
                <div className="mt-3 d-flex flex-column">
          <span className={'text-muted small'}>
            SKU: <span className="text-black">{product?.sku || 'N/A'}</span>
          </span>
                    <span className={'text-muted small'}>
            {t('table.category')}:{' '}
                        <span className="text-black">
              {product?.category?.name || 'N/A'}
            </span>
          </span>
                </div>
            </Col>
            <Col md={12} className={'mt-5'}>
                <div className="w-100  d-flex justify-content-center flex-column align-items-center">
                    {!!product?.additionalDescription && (
                        <>
                            <h4
                                className={
                                    'hover-underline-animation text-center cursor-pointer'
                                }
                            >
                                {t('table.additionalDescription')}
                            </h4>
                            <p className={'w-75 mt-1 text-center text-break'}>
                                {product?.additionalDescription}
                            </p>
                        </>
                    )}
                </div>
            </Col>
        </>
    );
};

export default ProductInfo;
