import React, { useState } from 'react'
import BlogForm from './BlogForm'
import { IBlog } from '../../../../shared/interfaces/IBlog';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { handleError } from '../../../../shared/functions/handleError';
import { multipartFormData } from '../../../../utils/api/api.service';
import { ADMIN_BLOG } from '../../../../utils/api/endpoints';

export default function CreateBlogPost() {
    const [currentBlog, setCurrentBlog] = useState<IBlog | null>(null);
    const [file, setFile] = useState<File | null>(null);

    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleChange = (name: string, value: string) => {
        setCurrentBlog(prev => ({ ...prev, [name]: value }) as IBlog)
    }

    const handleSubmit = () => {
        multipartFormData(file, ADMIN_BLOG, currentBlog, 'post', 'file').then((response) => {
            if (response) {
                toast.success(t("general.successfullyCreated"));
                navigate(-1);
            }
        }).catch(err => handleError(err))
    }

    return (

        <Form onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
        }}>
            <BlogForm handleChange={handleChange} currentBlog={currentBlog} file={file}
                setFile={setFile} />
        </Form>
    )
}
