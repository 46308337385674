import axios, { AxiosResponse } from "axios";

const defaultOptions = {
  headers: {
    "Content-Type": "application/json",
    accept: "text/html",
  },
};
export const instance = axios.create(defaultOptions);

instance.interceptors.request.use(
  (config) => {
    const token = JSON.parse(localStorage.getItem("token") ?? "{}");
    config!.headers!.Authorization = token ? `Bearer ${token}` : "";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const responseBody = (response: AxiosResponse) => response.data;

export const requests = {
  get: (url: string, params?: any) =>
    instance
      .get(url, { params })
      .then(responseBody)
      .catch((e) => {
        throw e;
      }),
  post: (url: string, body: {}, customHeaders?: any) =>
    instance
      .post(url, body, customHeaders)
      .then(responseBody)
      .catch((e) => {
        throw e;
      }),
  put: (url: string, body: {}, customHeaders?: any) =>
    instance
      .put(url, body, customHeaders)
      .then(responseBody)
      .catch((e) => {
        throw e;
      }),
  patch: (url: string, body: {}, customHeaders?: any) =>
    instance
      .patch(url, body, customHeaders)
      .then(responseBody)
      .catch((e) => {
        throw e;
      }),
  delete: (url: string) =>
    instance
      .delete(url)
      .then(responseBody)
      .catch((e) => {
        throw e;
      }),
};

export async function multipartFormData(
  image: any,
  url: string,
  body?: any,
  method = "put",
  formKey = "file",
  isMultiple = false,
  progressCallback?: (progress: number) => void
) {
  const formData = new FormData();
  if (image) {
    if (isMultiple) {
      for (const item of image) {
        formData.append(formKey, item);
      }
    } else {
      formData.append(formKey, image);
    }
  }
  formData.append("body", JSON.stringify(body));

  if (method === "put") {
    return instance.put(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  } else {
    return instance.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }
}

export const downloadFile = (
  url: string,
  format?: string,
  fileName?: string
) => {
  const token = JSON.parse(localStorage.getItem("token") ?? "{}");

  const method = "GET";
  axios
    .request({
      url,
      method,
      responseType: "blob",
      headers: {
        Authorization: token ? `Bearer ${token}` : "",
      },
    })
    .then((response) => {
      fileName = response.headers["content-disposition"].slice(21);
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute(
        "download",
        format ? `${fileName}.${format}` : `${fileName}`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch((error) => console.error(error));
};
