import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import SharedTable from '../../../shared/components/SharedTable';
import {useCachingState} from '../../../shared/hooks/useCachingState';
import {BlogService} from './service';
import {handleError} from '../../../shared/functions/handleError';
import {usePagination} from '../../../shared/hooks/usePagination';
import {IBlog} from '../../../shared/interfaces/IBlog';
import {blogTableHeader} from './table/blogTableHeader';
import Breadcrumbs from '../../../shared/components/Breadcrumbs';
import {Button} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import ConfirmationModal from '../../../shared/components/ConfirmationModal';
import {toast} from 'react-toastify';
import Pagination from '../../../shared/components/Pagination';
import BlogFilter from './components/BlogFilter';

export default function AdminBlog() {
    const [blogPosts, setBlogPosts] = useCachingState<IBlog[]>('blogList', []);
    const [selectedBlog, setSelectedBlog] = useState<IBlog | null>(null);
    const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
    const {
        pagination,
        count,
        setCount,
        changeFilterHandler,
        totalCount,
        setTotalCount,
    } = usePagination({
        perPage: 10,
        paginationKeys: {
            orderType: 'DESC',
        },
    });

    const navigate = useNavigate();
    const {t} = useTranslation();

    const handleConfirmDelete = () => {
        BlogService.deleteBlog(selectedBlog?.id as number)
            .then((response) => {
                if (response) {
                    changeFilterHandler('updatedAt', new Date().getTime());
                    setIsDeleteOpen(false);
                    toast.success(t('general.successfullyDeleted'));
                }
            })
            .catch((err) => handleError(err));
    };

    const handleClose = () => {
        setIsDeleteOpen(false);
        setSelectedBlog(null);
    };

    useEffect(() => {
        BlogService.getAllBlogPosts(pagination)
            .then((response) => {
                setBlogPosts(response?.data);
                setCount(Math.ceil(response.count / response.perPage));
                setTotalCount(response?.count);
            })
            .catch((err) => handleError(err));
    }, [pagination, setCount, setTotalCount]);

    return (
        <div>
            <div className="mb-3">
                <Breadcrumbs/>
            </div>

            <div className={'d-flex align-items-center justify-content-between mb-3'}>
                <Button
                    variant="dark"
                    className={'text-uppercase'}
                    onClick={() => {
                        navigate('/admin/blog/create');
                    }}
                >
                    {t('buttons.create')}
                </Button>

                <BlogFilter changeFilterHandler={changeFilterHandler}/>
            </div>

            <SharedTable
                data={blogPosts}
                headers={blogTableHeader}
                ctaHeaders={['actions']}
                ctaButtons={['view', 'delete']}
                emptyState={
                    <div className={'text-center'}>{t('general.listEmpty')}</div>
                }
                onButtonClick={async (btnName, data) => {
                    if (btnName === 'delete') {
                        setSelectedBlog(data);
                        setIsDeleteOpen(true);
                    }
                    if (btnName === 'view') {
                        navigate(`/admin/blog/edit/${data?.id}`);
                    }
                }}
            />

            <ConfirmationModal
                show={isDeleteOpen}
                handleClose={handleClose}
                handleConfirm={handleConfirmDelete}
                title={selectedBlog?.title as string}
            />

            <Pagination
                currentPage={pagination?.page}
                totalPages={count}
                totalItems={totalCount}
                onPerPageClick={(newPerPage) =>
                    changeFilterHandler('perPage', newPerPage)
                }
                onPageClick={(newPage) => changeFilterHandler('page', newPage)}
            />
        </div>
    );
}
