import { configureStore } from '@reduxjs/toolkit';
import authSlice from './slices/auth.slice';
import wishlistSlice from './slices/wishlist.slice';
import cartSlice from './slices/cart.slice';


export const store = configureStore({
    reducer: {
        auth: authSlice.reducer,
        wishlist: wishlistSlice.reducer,
        cart: cartSlice.reducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
