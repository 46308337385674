import { useTranslation } from "react-i18next";
import ProductCard from "./ProductCard";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { IProduct } from "../../../../shared/interfaces/IProduct";

interface IProps {
  products: IProduct[];
}

export default function WeeklyFeaturedProducts({ products }: IProps) {
  const { t } = useTranslation();
  return (
    <div className="mb-5">
      <div className="d-flex flex-column justify-content-center align-items-center mt-2 mt-md-5 mb-3">
        <h2 className="text-uppercase">
          {" "}
          {t("general.weeklyFeaturedProducts")}{" "}
        </h2>
      </div>

      <Swiper
        spaceBetween={20}
        slidesPerView={4}
        navigation={true}
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          400: {
            slidesPerView: 2,
          },
          639: {
            slidesPerView: 3,
          },
          865: {
            slidesPerView: 4,
          },
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        modules={[Autoplay, Navigation]}
      >
        {products?.map((product) => {
          return (
            <SwiperSlide key={product?.id}>
              <ProductCard product={product} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}
