import React from 'react'
import ImageHandler from '../../../../shared/components/ImageHandler'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom';

export default function ShopNowImage() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <>
            <ImageHandler
                src="https://uomo-nextjs-ecommerce.vercel.app/_next/image?url=%2Fassets%2Fimages%2Fhome%2Fdemo15%2Fbanner-1.jpg&w=1920&q=75"
                className="w-100" />
            <div
                className="bg-white d-flex flex-column justify-content-center align-items-center position-absolute-center p-5">
                <h4 className="text-uppercase"> Bring Out The Hidden Beauty </h4>
                <small className="text-muted"> Get styled with the high-fashion products and transform
                    yourself.</small>
                <span className="text-uppercase hover-underline-animation mt-2 fw-semibold cursor-pointer"
                    onClick={() => navigate("/shop")}>
                    {t("buttons.shopNow")}
                </span>
            </div>
        </>
    )
}
