import React from 'react';
import {Button, Form, InputGroup} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {FaFacebookF, FaInstagram} from 'react-icons/fa';
import {useNavigate} from 'react-router-dom';
import {footerData} from '../../utils/data/footerData';
import {webshopData} from '../../utils/data/webshopData';
import i18n, {resources} from '../../utils/localization/i18n';
import {scrollTo} from '../functions/scrollTo';
import {pageLogo} from './Header';
import ImageHandler from './ImageHandler';

const Footer = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();

    return (
        <footer className="bg-secondary py-3 w-100 border-top">
            <div className="container mt-5 px-0">
                <div className="row">
                    <div className="col-md-3 d-flex flex-column ps-md-0">
                        <ImageHandler src={pageLogo} className="header-logo"/>
                        <small className="mt-3">
                            {webshopData?.streetAddress}, {webshopData?.country}
                        </small>
                        <small className="mt-3"> {webshopData?.email} </small>
                        <small className="mt-3"> {webshopData.phone} </small>

                        <div className="my-3">
                            <a href={webshopData?.instagram} className={'text-black'} target={'_blank'}>
                                <FaFacebookF className="me-3 cursor-pointer"/>
                            </a>
                            <a href={webshopData?.instagram} className={'text-black'} target={'_blank'}>
                                <FaInstagram className="me-3 cursor-pointer"/>
                            </a>
                        </div>
                    </div>
                    {footerData?.map((footer) => {
                        return (
                            <div
                                className="col-6 col-md-2 mt-3 mt-md-0 mb-3 md-md-0"
                                key={footer.title}
                            >
                                <h6 className="mb-md-5 text-uppercase"> {t(footer?.title)} </h6>
                                <ul className="list-unstyled">
                                    {footer?.items.map((item) => {
                                        return (
                                            <li
                                                key={item.id}
                                                onClick={() => {
                                                    navigate(item.navigateTo);
                                                    scrollTo();
                                                }}
                                                className="my-2 my-md-3 small cursor-pointer"
                                            >
                                                {t(item.name)}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        );
                    })}

                    <div className="col-md-3">
                        <h6 className="mb-md-5 text-uppercase">{t('footer.subscribe')}</h6>
                        <small> {t('messages.newsletter')} </small>

                        <InputGroup className="mt-3">
                            <Form.Control
                                placeholder={t('general.yourEmailAddress')}
                                aria-describedby="basic-addon2"
                                className="p-3 rounded-0"
                            />
                            <Button
                                variant="outline-secondary"
                                className="bg-white border-start-0 email-submit "
                            >
                                {t('buttons.submit')}
                            </Button>
                        </InputGroup>
                    </div>
                </div>

                <div className="row border-top border-muted pt-3 mt-5 align-items-center">
                    <div className="col-6 px-md-0">
                        <div className={'d-flex align-items-center'}>
                            <span>
                            © {new Date()?.getFullYear()} | Developed by
                            </span>
                            <a href="https://lazarevic.live/" target={'_blank'}
                               className={'text-primary ms-1 text-decoration-none'}>
                                Lazarević
                            </a>
                            <span className={'mx-1'}>
                                &
                            </span>
                            <a href="https://mattemattika.com/" target={'_blank'}
                               className={'text-primary text-decoration-none'}>
                                Tomić
                            </a>
                        </div>
                    </div>

                    <div className="col-6 d-flex justify-content-end px-0">
                        <select
                            className="form-select bg-transparent w-120px text-muted"
                            value={i18n.language}
                            onChange={(e) => {
                                i18n.changeLanguage(e?.target?.value);
                                localStorage.setItem('language', e?.target?.value);
                            }}
                        >
                            {Object.keys(resources)?.map((language) => {
                                return (
                                    <option value={language} key={language}>
                                        {t(`language.${language}`)}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
