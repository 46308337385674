import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { formatDate } from '../../../../shared/functions/formatDate'
import { useTranslation } from 'react-i18next';
import { IOrder } from '../../../../shared/interfaces/IOrder';
import { formatPrice } from '../../../../shared/functions/formatPrice';

interface IProps {
    placedOrder: IOrder
}

export default function ConfirmedOrderDetails({ placedOrder }: IProps) {
    const { t } = useTranslation();

    return (
        <Row>
            <Col md={3}>
                <span className="text-muted"> {t("cart.orderNumber")} </span>
            </Col>

            <Col md={3}>
                <span className="text-muted"> {t("cart.date")} </span>
            </Col>

            <Col md={3}>
                <span className="text-muted"> {t("cart.total")} </span>
            </Col>

            <Col md={3}>
                <span className="text-muted"> {t("cart.paymentMethod")}</span>
            </Col>

            <Col md={3}>
                <h5> #{placedOrder?.id} </h5>
            </Col>

            <Col md={3}>
                <h5> {formatDate(placedOrder?.createdAt as string)}.</h5>
            </Col>

            <Col md={3}>
                <h5> {formatPrice(placedOrder?.total)} </h5>
            </Col>

            <Col md={3}>
                <h5> {t("cart.cashOnDelivery")}</h5>
            </Col>
        </Row>
    )
}
