import React from "react";
import { useSelector } from "react-redux";
import LeadingTitle from "../../../shared/components/LeadingTitle";
import { RootState } from "../../../store/store";
import ShopProduct from "../shop/components/ShopProduct";
import { useTranslation } from "react-i18next";
import EmptyList from "../../../shared/components/EmptyCart";

interface IProps {
  isMyAccount?: boolean;
}

export default function Wishlist({ isMyAccount = false }: IProps) {
  const wishlist = useSelector((state: RootState) => state.wishlist);
  const { t } = useTranslation();

  return (
    <>
      {!isMyAccount && <LeadingTitle title={t("general.wishlist")} />}

      <div className="container mb-5">
        {!!wishlist?.length ? (
          <div className="row mt-3">
            {wishlist?.map((product) => {
              return (
                <div className="col-md-3 ps-0">
                  <ShopProduct shopProduct={product} />
                </div>
              );
            })}
          </div>
        ) : (
          <EmptyList isCart={false} />
        )}
      </div>
    </>
  );
}
