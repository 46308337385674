import React, {useEffect, useState} from 'react';
import {RootState} from '../../../../store/store';
import {useSelector} from 'react-redux';
import {OrdersService} from '../../../../app/admin/orders/service';
import {handleError} from '../../../functions/handleError';
import {scrollTo} from '../../../functions/scrollTo';
import {IOrder} from '../../../interfaces/IOrder';
import SharedTable from '../../SharedTable';
import {useNavigate} from 'react-router-dom';

const userOrdersHeaders = [
    {key: 'id', label: 'id'},
    {key: 'createdAt', label: 'createdAt'},
    {key: 'user.firstName', label: 'firstName'},
    {key: 'user.lastName', label: 'lastName'},
    {key: 'status', label: 'status'},
    {key: 'total', label: 'total'}
];


export default function UserOrders() {
    const {userData} = useSelector((state: RootState) => state.auth);
    const userId = userData?.id;
    const [userOrders, setUserOrders] = useState<IOrder[]>([]);
    const navigate = useNavigate();


    useEffect(() => {
        userId && OrdersService.getOrdersByUserId(userId as number).then((response) => {
            setUserOrders(response);
        }).catch(err => handleError(err));
    }, [userId])

    return (
        <div>
            <SharedTable
                data={userOrders}
                headers={userOrdersHeaders}
                ctaHeaders={['actions']}
                ctaButtons={['view']}
                emptyState={
                    <div className={'text-center'}>
                        Sorry this list is empty ...
                    </div>
                }
                onButtonClick={async (btnName, data) => {

                    if (btnName === 'view') {
                        scrollTo();
                        navigate(`/users/my-orders/${data?.id}`)
                    }
                }}
            />
        </div>
    )
}
