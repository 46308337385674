import React, {SetStateAction} from 'react';
import {BsChevronCompactLeft, BsChevronCompactRight} from 'react-icons/bs';
import Fancybox from '../../../../shared/components/Fancybox';
import ImageHandler from '../../../../shared/components/ImageHandler';
import {IPhoto} from '../../../../shared/interfaces/IPhoto';
import {IProduct} from '../../../../shared/interfaces/IProduct';

interface IProps {
    product: IProduct;
    activeImageId: number;
    currentImage: IPhoto;
    setActiveImageId: React.Dispatch<SetStateAction<number>>
}

const ProductFancybox = ({product, activeImageId, setActiveImageId, currentImage}: IProps) => {
    return (
        <Fancybox
            options={{
                Carousel: {
                    infinite: false,
                },
            }}
        >
            <div className={'position-relative'}>
                <button className={'product-button left'}
                        disabled={!!(product?.productPhotos?.length && product?.productPhotos?.[0]?.id === currentImage?.id)}
                        onClick={() => {
                            const currentIndex = product?.productPhotos?.findIndex(picture => picture.id === activeImageId)
                            setActiveImageId(product?.productPhotos[currentIndex! - 1]?.id as number)
                        }}>
                    <BsChevronCompactLeft className={'text-muted'}/>
                </button>
                <a data-fancybox="gallery" href={currentImage?.path}>

                    <ImageHandler src={currentImage?.path} className={'product-img-big'}/>

                </a>
                <button
                    disabled={!!(product?.productPhotos?.length && product?.productPhotos?.[product?.productPhotos?.length - 1]?.id === currentImage?.id)}

                    className={'product-button right'} onClick={() => {
                    const currentIndex = product?.productPhotos?.findIndex(picture => picture.id === activeImageId)
                    setActiveImageId(product?.productPhotos[currentIndex! + 1]?.id as number)
                }}>
                    <BsChevronCompactRight className={'text-muted'}/>
                </button>
            </div>
        </Fancybox>
    );
};

export default ProductFancybox;
