import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { ICompany } from '../../../interfaces/ICompany';
import { CompanyService } from '../../../../app/admin/company/service';
import { handleError } from '../../../functions/handleError';
import ImageHandler from '../../ImageHandler';
import { multipartFormData } from '../../../../utils/api/api.service';
import { ADMIN_COMPANY } from '../../../../utils/api/endpoints';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function CompanyDashboard() {
    const [currentCompanyDashboard, setCurrentCompanyDashboard] = useState<ICompany | null>(null);
    const [file, setFile] = useState<File | null>(null);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setCurrentCompanyDashboard(prev => ({ ...prev, [name]: value }) as ICompany);
    }

    const handleSubmit = () => {
        multipartFormData(file, ADMIN_COMPANY, {
            title: currentCompanyDashboard?.title,
            description: currentCompanyDashboard?.description,
        }, 'put', 'file').then((response) => {
            if (response) {
                toast.success(t("general.successfullyUpdated"));
                navigate(-1);
            }
        }).catch(err => handleError(err))
    }

    useEffect(() => {
        CompanyService.getCompanyDetails().then((response) => {
            setCurrentCompanyDashboard(response);
        }).catch(err => handleError(err));
    }, []);

    return (
        <Form onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
        }}>
            <h5 className='mb-2'> {t("messages.updateCompanyDashboard")}</h5>
            <Form.Control type="text"
                name="title"
                value={currentCompanyDashboard?.title}
                onChange={handleChange}
                className='rounded-0 p-3' />

            <Form.Control type="text"
                name="description"
                as="textarea"
                rows={3}
                value={currentCompanyDashboard?.description}
                onChange={handleChange}
                className='rounded-0 p-3 my-3' />

            <input type="file"
                name="path"
                onChange={(e) => setFile(e?.target?.files?.[0] as File)}
                accept='image/*'
                className='form-control rounded-0 p-3' />

            {(file || currentCompanyDashboard?.path) &&
                <ImageHandler
                    className="object-fit-cover w-100 cursor-pointer h-400px mt-3"
                    src={(file ? URL.createObjectURL(file) : currentCompanyDashboard?.path) as string}
                />
            }

            <div className="d-flex justify-content-end mt-3">
                <Button variant='dark' type="submit"> {t("buttons.update")} </Button>
            </div>
        </Form>
    )
}
