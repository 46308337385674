import React, { useState } from 'react'
import { Button, Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { VscEye, VscEyeClosed } from 'react-icons/vsc';

interface IProps {
    changeHandler: React.ChangeEventHandler<HTMLInputElement>
}

export default function LoginForm({ changeHandler }: IProps) {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [showPassword, setShowPassword] = useState(false);

    return (
        <div className="card border-0">
            <div className="card-body p-0">
                <div className="d-flex justify-content-center">
                    <span className="text-uppercase me-5 fw-semibold text-decoration-underline cursor-pointer">
                        {t("login.login")}
                    </span>
                    <span
                        className="text-uppercase text-muted cursor-pointer"
                        onClick={() => {
                            navigate("/register");
                        }}>
                        {t("login.register")}
                    </span>
                </div>

                <input
                    placeholder={t("general.email")}
                    type="email"
                    required
                    name="email"
                    onChange={changeHandler}
                    className="rounded-0 py-3 mt-5 form-control"
                />

                <div className="my-3">
                    <Form.Group controlId="formBasicPassword">
                        <InputGroup>
                            <Form.Control
                                type={showPassword ? "text" : "password"}
                                required
                                name="password"
                                placeholder={t("general.password")}
                                onChange={changeHandler}
                                className="rounded-0 py-3"
                            />

                            <InputGroup.Text>
                                {!showPassword ?
                                    <VscEye className="cursor-pointer" onClick={() => {
                                        setShowPassword((prev) => !prev);
                                    }
                                    } /> : <VscEyeClosed className="cursor-pointer" onClick={() => {
                                        setShowPassword((prev) => !prev);
                                    }
                                    } />}
                            </InputGroup.Text>
                        </InputGroup>
                    </Form.Group>
                </div>

                <Button className="w-100 rounded-0 bg-dark border-0 py-3 text-uppercase" type="submit">
                    {t("buttons.log-in")}
                </Button>

            </div>
        </div>
    )
}
