import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { UsersService } from '../service';
import { handleError } from '../../../../shared/functions/handleError';
import { IUser } from '../../../../shared/interfaces/IUser';
import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { multipartFormData } from '../../../../utils/api/api.service';
import { toast } from 'react-toastify';
import { PROFILE_URL } from '../../../../utils/api/endpoints';
import UserForm from './components/UserForm';

export default function SingleUser() {
    const { id } = useParams();
    const [user, setUser] = useState<IUser | null>(null);
    const { t } = useTranslation();
    const [file, setFile] = useState<File | null>(null);
    const navigate = useNavigate();

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setUser(prev => ({ ...prev, [name]: value }) as IUser);
    }

    const handleSubmit = () => {
        multipartFormData(file, `${PROFILE_URL}/${id}`, user, 'put', 'file').then((response) => {
            if (response) {
                navigate(-1);
                toast.success(t("general.successfullyUpdated"))
            }
            setUser(null);
        }).catch(err => handleError(err))
    }

    useEffect(() => {
        UsersService.getUserById(Number(id)).then((response) => {
            console.log(response, "USER")
            setUser(response);
        }).catch(err => handleError(err));
    }, [id]);

    return (
        <div className='mt-3'>
            <Form onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
            }}>

                <UserForm user={user}
                    handleChange={handleChange}
                    file={file}
                    setFile={setFile} />
            </Form>
        </div>
    )
}
