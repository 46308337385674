import React from 'react';
import {useTranslation} from 'react-i18next';
import LeadingTitle from '../../../shared/components/LeadingTitle';
import {webshopData} from '../../../utils/data/webshopData';

const ReturnPolicy = () => {
    const {t} = useTranslation()
    return (
        <>
            <LeadingTitle title={t('navigation.returnItemPolicy')} className="p-0"/>
            <div className="container  mt-3">
                <h2 className={'mb-3 fw-bold'}>Zamjena ili povrat artikla</h2>
                <h5 className={'fw-bold mb-3'}>
                    PROCEDURA ZAMJENE ILI VRAĆANJA ARTIKLA
                </h5>
                <p>
                    Ukoliko ste nakon prijema artikla ustanovili da želite da ga zamjenite za drugu boju ili drugi
                    artikal, molimo Vas da nas kontaktirate na neki od sljedećih načina:
                </p>
                <p>
                    <a href={webshopData?.instagram} target={'_blank'} className={'fw-bold text-primary'}>
                        Instagram
                    </a> privatnom porukom ili na email:{' '}
                    <b>{webshopData?.email}</b>
                </p>
                <p>
                    <b> Prvi uslov</b> za zamjenu ili povrat da se proizvod ne koristi (
                    <span className="text-decoration-underline">
                        vršimo provjeru da li je
                    proizvod
                    korišten
                    </span>
                    ).
                </p>
                <p>
                    <b>Drugi uslov</b> za zamjenu ili povrat je da nije prošlo više od 10 dana od kupovine.
                </p>
                <p>
                    Prilikom povraćaja robe obavezno je robu vratiti u originalnom i neoštećenom ambalažom. Po prijemu
                    proizvoda, utvrdiće se da li je proizvod ispravan i neoštećen. Ukoliko se utvrdi da je nastupila
                    neispravnost ili oštećenje proizvoda krivicom kupca, odbiće se zamjena i proizvod će biti vraćen
                    kupcu na njegov trošak.
                </p>
                <p className={'fw-bold'}>
                    Poručeni artikal nije moguće mijenjati / vratiti za novac ukoliko je nošen/korišten.
                </p>
                <h5 className="mb-3 fw-bold">
                    Troškovi zamjene ili povrata
                </h5>
                <p>
                    Troškove zamjene ili povrata robe i novca snosi kupac, sem u slučajevima kada kupac dobije
                    neispravan ili pogrešan artikal.
                </p>
                <p>
                    Ako ste iz {webshopData?.city}, zamjenu ili povrat možete obaviti lično u našoj polovnici
                </p>
            </div>
        </>
    );
};

export default ReturnPolicy;
