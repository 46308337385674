import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {RiSortAsc, RiSortDesc} from 'react-icons/ri';
import FilterDrawer from '../../../../shared/components/FilterDrawer';
import Search from '../../../../shared/components/Search';
import {EOrderType} from '../../../../shared/enums/EOrderType';

interface IProps {
    changeFilterHandler: (
        name: string,
        value: string | number | boolean | null
    ) => void;
    pagination: any;
}

const searchKeys = ['title'];

const CategoriesFilter = ({changeFilterHandler, pagination}: IProps) => {
    const [showFilter, setShowFilter] = useState(false);
    const {t} = useTranslation();

    return (
        <>
            <div className={'d-flex align-items-center'}>
                <div className="border-end px-3">
                    <h6
                        className={
                            'text-uppercase cursor-pointer d-flex align-items-center'
                        }
                        onClick={() => {
                            changeFilterHandler(
                                'orderType',
                                pagination?.orderType === EOrderType.ASC
                                    ? EOrderType.DESC
                                    : EOrderType.ASC
                            );
                        }}
                    >
                        {pagination?.orderType}
                        {pagination?.orderType === EOrderType.ASC ? (
                            <RiSortAsc className={'ms-1'}/>
                        ) : (
                            <RiSortDesc className={'ms-1'}/>
                        )}
                    </h6>
                </div>

                <h6
                    className={
                        'text-uppercase cursor-pointer hover-underline-animation ms-3'
                    }
                    onClick={() => setShowFilter(true)}
                >
                    {t('general.filters')}
                </h6>
            </div>

            <FilterDrawer show={showFilter} handleClose={() => setShowFilter(false)}>
                <Search changeFilterHandler={changeFilterHandler} keys={searchKeys}/>
            </FilterDrawer>
        </>
    );
};

export default CategoriesFilter;
