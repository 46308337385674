import React, {useEffect, useState} from 'react';
import ImageHandler from '../../../../shared/components/ImageHandler';
import {Button} from 'react-bootstrap';
import {ICompany} from '../../../../shared/interfaces/ICompany';
import {CompanyService} from '../../../admin/company/service';
import {handleError} from '../../../../shared/functions/handleError';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

export default function DashboardImageText() {
    const [companyInformation, setCompanyInformation] = useState<ICompany | null>(
        null
    );
    const {t} = useTranslation();
    const navigate = useNavigate();
    const cachedImg = localStorage?.getItem('dashboardImg')
        ? localStorage?.getItem('dashboardImg')
        : '/images/dashboard-img.webp';
    useEffect(() => {
        CompanyService.getCompanyDetails()
            .then((response) => {
                setCompanyInformation(response);
                response?.path && localStorage.setItem('dashboardImg', response?.path);
            })
            .catch((err) => handleError(err));
    }, []);

    return (
        <>
            {
                <ImageHandler
                    src={String(companyInformation?.path || cachedImg)}
                    className="w-100 object-fit-cover h-80vh dashboard-landing-img"
                />
            }
            <div className="container">
                <div className="dashboard-info-text">
                    <h1 className="text-uppercase text-white fw-bold"> {companyInformation?.title} </h1>
                    <p className="my-3 w-50 text-white"> {companyInformation?.description}</p>
                    <Button
                        variant="dark"
                        className="rounded-0 w-25 p-3"
                        onClick={() => navigate('/shop')}
                    >
                        {t('buttons.view')}
                    </Button>
                </div>
            </div>
        </>
    );
}
