import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { myAccountData } from "../../../utils/data/myAccountData";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import authSlice from "../../../store/slices/auth.slice";
import UserOrders from "./orders/UserOrders";
import MyAccountAddress from "./components/MyAccountAddress";
import AccountDetails from "./account-details/AccountDetails";
import { RootState } from "../../../store/store";
import { ERole } from "../../enums/ERole";
import Wishlist from "../../../app/public/wishlist";
import wishlistSlice from "../../../store/slices/wishlist.slice";
import cartSlice from "../../../store/slices/cart.slice";
import CompanyDashboard from "./components/CompanyDashboard";
import AdminDashboard from "../../../app/admin/dashboard";

export default function MyAccount() {
  const { t } = useTranslation();
  let [searchParams, setSearchParams] = useSearchParams();
  const [activePageId, setActivePageId] = useState<number>(
    +(searchParams?.get("route") || 1)
  );
  const { userData } = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const componentsMap = {
    1: <AdminDashboard />,
    2: <UserOrders />,
    3: <CompanyDashboard />,
    4: <MyAccountAddress />,
    5: <AccountDetails />,
    6: <Wishlist isMyAccount={true} />,
  };

  const getCorrectTabComponent = (tabId: keyof typeof componentsMap) => {
    return componentsMap[tabId] || `No component for ID: ${tabId}`;
  };

  return (
    <>
      <div className="row mt-5">
        <div className="col-12">
          <h2 className="text-uppercase fw-bold">
            {t(
              `${
                myAccountData
                  ?.filter((data) =>
                    data?.roles?.includes(userData?.role as ERole)
                  )
                  ?.find((route) => route.id === activePageId)?.title
              }`
            )}
          </h2>
        </div>
      </div>

      <div className="row my-5">
        <div className="col-md-2">
          <ul className="list-unstyled d-flex justify-content-center flex-wrap d-md-block">
            {myAccountData
              ?.filter((data) => data?.roles?.includes(userData?.role as ERole))
              ?.map((data) => {
                const isActive = activePageId === data?.id;
                return (
                  <li
                    key={data.id}
                    className={`d-flex w-fit hover-underline-animation text-uppercase text-${
                      isActive ? "primary" : "dark"
                    } mb-3 fw-semibold cursor-pointer mx-3 mx-md-0`}
                    onClick={() => {
                      setActivePageId(data?.id);
                      setSearchParams((params) => {
                        params.set("route", String(data?.id));
                        return params;
                      });
                      if (data?.id === 7) {
                        localStorage.clear();
                        navigate("/login");
                        dispatch(authSlice.actions.setLogoutState());
                        dispatch(wishlistSlice.actions.setWishlist([]));
                        dispatch(cartSlice.actions.setCart([]));
                      }
                    }}
                  >
                    {t(data.title)}
                  </li>
                );
              })}
          </ul>
        </div>

        <div className="col-md-10">
          {getCorrectTabComponent(activePageId as keyof typeof componentsMap)}
        </div>
      </div>
    </>
  );
}
