import { createSlice } from '@reduxjs/toolkit';
import { IProduct } from '../../shared/interfaces/IProduct';


const initialState: IProduct[] = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart') as string) : [];

const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        setCart: (state, action) => {
            state = action.payload;
            return state;
        }
    },
});

export default cartSlice;
