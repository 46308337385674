import React from 'react'
import { Button, Form } from 'react-bootstrap'
import { IFaq } from '../../../../../shared/interfaces/IFaq'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface IProps {
    currentFaq: IFaq | null,
    handleChange: React.ChangeEventHandler<HTMLInputElement>,
}

export default function FaqForm({ currentFaq, handleChange }: IProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return (
        <div>
            {currentFaq?.id &&
                <Form.Control type="text"
                    value={"ID: " + currentFaq?.id}
                    className={'rounded-0 p-3 mb-3'}
                    disabled />
            }
            <Form.Control type="text"
                placeholder={t("table.question")}
                className={'rounded-0 p-3 mb-3 '}
                name="name"
                value={currentFaq?.name}
                onChange={handleChange} />

            <Form.Control
                as="textarea"
                type="text"
                placeholder={t("table.answer")}
                className={'rounded-0 p-3 mb-3 overflow-auto h-200px'}
                name="description"
                value={currentFaq?.description}
                onChange={handleChange}
            />
            <div className='d-flex justify-content-end mb-5'>
                <Button variant="dark"
                    onClick={() => navigate(-1)}
                    className={'text-uppercase'}>
                    {t("buttons.cancel")}
                </Button>
                <Button variant="primary"
                    type="submit"
                    className={'ms-2 text-uppercase'}>
                    {currentFaq?.id ? t("buttons.update") : t("buttons.create")}
                </Button>

            </div>
        </div>
    )
}
