import React, { SetStateAction, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import RegisterForm from "../../../../authentification/register/components/RegisterForm";
import OrderDetails from "./OrderDetails";
import { IUser } from "../../../../shared/interfaces/IUser";
import { IProduct } from "../../../../shared/interfaces/IProduct";
import { OrdersService } from "../../../admin/orders/service";
import { ICreateOrder, IOrder } from "../../../../shared/interfaces/IOrder";
import { handleError } from "../../../../shared/functions/handleError";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import cartSlice from "../../../../store/slices/cart.slice";

interface IProps {
  changeHandler: React.ChangeEventHandler<HTMLInputElement>;
  currentUser: IUser | null;
  cart: IProduct[];
  setCurrentStep: React.Dispatch<SetStateAction<number>>;
  setPlacedOrder: React.Dispatch<SetStateAction<IOrder | null>>;
}

export default function Checkout({
  changeHandler,
  currentUser,
  cart,
  setCurrentStep,
  setPlacedOrder,
}: IProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handlePlaceOrder = () => {
    const orderData = cart?.map((product) => ({
      productId: product?.id,
      quantity: product?.cartQuantity,
    })) as ICreateOrder[];
    setIsLoading(true);

    OrdersService.createOrder(orderData)
      .then((response) => {
        if (response) {
          toast.success(t("general.successfullyPlacedOrder"));
          setPlacedOrder({
            ...response,
            items: response?.items?.map((product) => ({
              ...product,
              cartQuantity: product?.quantity,
            })),
          });
          setCurrentStep((prev) => prev + 1);
          dispatch(cartSlice.actions.setCart([]));
          localStorage.removeItem("cart");
        }
      })
      .catch((err) => handleError(err))
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Row className={"mt-5"}>
      <Col md={8} className={"px-0"}>
        <h5 className={"text-uppercase"}> {t("cart.billingDetails")} </h5>
        <RegisterForm
          changeHandler={changeHandler}
          isDisabled
          currentUser={currentUser}
        />
      </Col>
      <Col md={4} className={"px-0"}>
        <OrderDetails cart={cart} />
        <div className={"d-flex justify-content-end mt-3"}>
          <Button
            variant="dark"
            className={"text-uppercase w-100 ms-5 p-3"}
            onClick={() => {
              handlePlaceOrder();
            }}
            disabled={isLoading}
          >
            {isLoading && (
              <span
                className="spinner-border spinner-border-sm me-1"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            {t("buttons.placeOrder")}
          </Button>
        </div>
      </Col>
    </Row>
  );
}
