import { IPagination } from "../../../shared/interfaces/IPagination"
import { IProduct } from "../../../shared/interfaces/IProduct"
import { requests } from "../../../utils/api/api.service"
import { ADMIN_PRODUCT_IMG, ADMIN_PRODUCTS } from "../../../utils/api/endpoints"

export const ProductService = {
    getAllProducts: (pagination: IPagination): Promise<{ data: IProduct[], count: number, perPage: number }> =>
        requests.get(ADMIN_PRODUCTS, pagination),

    getProductById: (productId: number): Promise<IProduct> =>
        requests.get(`${ADMIN_PRODUCTS}/${productId}`),

    deleteProduct: (productId: number) =>
        requests.delete(`${ADMIN_PRODUCTS}/${productId}`),

    deleteProductImg: (photoId: number) =>
        requests.delete(`${ADMIN_PRODUCT_IMG}/${photoId}`),
}