import React, { useEffect } from "react";
import { useCachingState } from "../../../shared/hooks/useCachingState";
import DashboardCard from "./components/DashboardCard";
import { AdminDashboardService } from "./service";
import { handleError } from "../../../shared/functions/handleError";
import {
  IDashboard,
  IDashboardItem,
} from "../../../shared/interfaces/IDashboard";
import { BiCategory } from "react-icons/bi";
import { AiOutlineProduct } from "react-icons/ai";
import { HiMiniUsers } from "react-icons/hi2";
import { useTranslation } from "react-i18next";
import { IoCart } from "react-icons/io5";
import { IoCheckmarkDoneSharp } from "react-icons/io5";

const AdminDashboard = () => {
  const [dashboard, setDashboard] = useCachingState<IDashboard | null>(
    "dashboardData",
    null
  );
  const { t } = useTranslation();

  const dashboardItems = [
    {
      id: 1,
      title: t("navigation.categories"),
      number: dashboard?.nbOfCategories,
      navigateTo: "/admin/categories",
      icon: <BiCategory className="header-icons" />,
    },
    {
      id: 4,
      title: t("navigation.orders"),
      number: dashboard?.nbOfOrders,
      navigateTo: "/admin/orders",
      icon: <IoCart className="header-icons" />,
    },
    {
      id: 5,
      title: t("navigation.products"),
      number: dashboard?.nbOfProducts,
      navigateTo: "/admin/products",
      icon: <AiOutlineProduct className="header-icons" />,
    },
    {
      id: 6,
      title: t("navigation.users"),
      number: dashboard?.nbOfUsers,
      navigateTo: "/admin/users",
      icon: <HiMiniUsers className="header-icons" />,
    },
    {
      id: 2,
      title: t("general.completedOrders"),
      number: dashboard?.nbOfCompletedOrders,
      icon: <IoCheckmarkDoneSharp className="header-icons" />,
    },
    {
      id: 3,
      title: t("general.earnings"),
      number: dashboard?.nbOfEarnings,
      icon: "BAM",
    },
  ];

  useEffect(() => {
    AdminDashboardService.getDashboardInformation()
      .then((response) => setDashboard(response))
      .catch((err) => handleError(err));
  }, []);

  return (
    <div className="row">
      {dashboardItems?.map((dashboardItem) => {
        return (
          <div className="col-md-3 my-2" key={dashboardItem?.id}>
            <DashboardCard dashboardItem={dashboardItem as IDashboardItem} />
          </div>
        );
      })}
    </div>
  );
};

export default AdminDashboard;
