import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { Button, Form } from 'react-bootstrap';
import { IUser } from '../../../interfaces/IUser';
import { handleError } from '../../../functions/handleError';
import { toast } from 'react-toastify';
import { multipartFormData } from '../../../../utils/api/api.service';
import { PROFILE_URL } from '../../../../utils/api/endpoints';
import authSlice from '../../../../store/slices/auth.slice';


export default function MyAccountAddress() {
    const { t } = useTranslation();
    const { userData } = useSelector((state: RootState) => state.auth);
    const [currentUser, setCurrentUser] = useState<IUser | null>(null);
    const dispatch = useDispatch();

    const handleSubmit = () => {
        multipartFormData(null, PROFILE_URL, currentUser, 'put', 'file').then((response) => {
            console.log(response, "file response")
            const { data } = response;
            dispatch(authSlice.actions.setLoginState({
                isAuthenticated: true,
                userData: data
            }))
            toast.success(t("general.successfullyUpdated"))
        }).catch(err => handleError(err));
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setCurrentUser(prev => ({ ...prev, [name]: value }) as IUser);
    }

    useEffect(() => {
        if (userData?.id) {
            setCurrentUser(userData)
        }
    }, [userData?.id, userData])

    return (
        <div>
            <p> {t("messages.myAccountAddress")} </p>
            <div className="d-flex justify-content-between mt-5">
                <h5 className='text-uppercase mb-3'> {t("general.address")} </h5>
            </div>

            <Form onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
            }}>
                <Form.Control type="text"
                    placeholder={t("general.street")}
                    name="streetAddress"
                    value={currentUser?.streetAddress}
                    className="rounded-0 p-3 mb-2"
                    onChange={handleChange}
                    required
                />

                <Form.Control type="text"
                    placeholder={t("general.city")}
                    name="city"
                    value={currentUser?.city}
                    className="rounded-0 p-3 mb-2"
                    onChange={handleChange}
                    required
                />

                <Form.Control type="text"
                    placeholder={t("general.country")}
                    name="country"
                    value={currentUser?.country}
                    className="rounded-0 p-3 mb-2"
                    onChange={handleChange}
                    required
                />

                <Form.Control type="text"
                    placeholder={t("general.zipCode")}
                    name="zipCode"
                    value={currentUser?.zipCode}
                    className="rounded-0 p-3 mb-2"
                    onChange={handleChange}
                    required
                />

                <Form.Control type="email"
                    placeholder={t("general.email")}
                    name="email"
                    value={currentUser?.email}
                    className="rounded-0 p-3 mb-2"
                    onChange={handleChange}
                />

                <Form.Control type="text"
                    placeholder={t("general.phone")}
                    name="phone"
                    value={currentUser?.phone}
                    className="rounded-0 p-3 mb-2"
                    onChange={handleChange}
                    required
                />

                <Button variant='dark' className="w-25 rounded-0 p-3" type="submit"> {t("buttons.update")}</Button>
            </Form>

        </div>
    )
}
