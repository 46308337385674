import React, { SetStateAction } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IUpdatePassword } from "../../../../interfaces/IUpdatePassword";
import { VscEye, VscEyeClosed } from "react-icons/vsc";

interface IProps {
  showPassword: boolean;
  setShowPassword: React.Dispatch<SetStateAction<boolean>>;
  handleChangePassword: React.ChangeEventHandler<HTMLInputElement>;
  passwordState: IUpdatePassword;
}

export function PasswordForm({
  showPassword,
  setShowPassword,
  handleChangePassword,
  passwordState,
}: IProps) {
  const { t } = useTranslation();

  return (
    <div className="row mt-5">
      <div className="col-12 px-0">
        <h6 className="text-uppercase mt-5 mb-3">
          {" "}
          {t("general.passwordChange")}{" "}
        </h6>
      </div>
      <div className="col-md-6 ps-0">
        <Form.Group controlId="formBasicPassword">
          <InputGroup>
            <Form.Control
              type={showPassword ? "text" : "password"}
              name="oldPassword"
              value={passwordState?.oldPassword}
              placeholder={t("general.currentPassword")}
              className="rounded-0 mb-3 p-3"
              onChange={handleChangePassword}
            />

            <InputGroup.Text className="mb-3 rounded-0">
              {!showPassword ? (
                <VscEye
                  className="cursor-pointer"
                  onClick={() => {
                    setShowPassword((prev) => !prev);
                  }}
                />
              ) : (
                <VscEyeClosed
                  className="cursor-pointer"
                  onClick={() => {
                    setShowPassword((prev) => !prev);
                  }}
                />
              )}
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </div>

      <div className="col-md-6 px-0">
        <Form.Group controlId="formBasicPassword">
          <InputGroup>
            <Form.Control
              type={showPassword ? "text" : "password"}
              name="newPassword"
              value={passwordState?.newPassword}
              placeholder={t("general.newPassword")}
              className="rounded-0 mb-3 p-3"
              onChange={handleChangePassword}
            />

            <InputGroup.Text className="mb-3 rounded-0">
              {!showPassword ? (
                <VscEye
                  className="cursor-pointer"
                  onClick={() => {
                    setShowPassword((prev) => !prev);
                  }}
                />
              ) : (
                <VscEyeClosed
                  className="cursor-pointer"
                  onClick={() => {
                    setShowPassword((prev) => !prev);
                  }}
                />
              )}
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </div>
      <div className="col-md-6 px-0">
        <Button variant="dark" className="w-50 rounded-0 p-3" type="submit">
          {" "}
          {t("buttons.save")}
        </Button>
      </div>
    </div>
  );
}
