import React, {useEffect, useState} from 'react'
import {Col, Row} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {handleError} from '../../../../shared/functions/handleError';
import {IOrder} from '../../../../shared/interfaces/IOrder';
import {RootState} from '../../../../store/store';
import {OrdersService} from '../service';
import SingleOrderDetails from './components/SingleOrderDetails';
import SingleOrderTable from './components/SingleOrderTable';

export default function SingleOrder() {
    const {id} = useParams();
    const {userData} = useSelector((state: RootState) => state.auth);
    const [order, setOrder] = useState<IOrder | null>(null)
    useEffect(() => {
        if (id && userData?.id) {
            OrdersService.getSingleOrder(+id, userData?.id)
                .then(response => {
                    setOrder(response)
                }).catch(err => handleError(err))
        }
    }, [id, userData?.id])
    return (
        <>
            {order?.id && <Row>
                <Col md={8}>
                    <SingleOrderTable order={order}/>
                </Col>
                <Col md={4}>
                    <SingleOrderDetails order={order}/>
                </Col>
            </Row>}
        </>
    )
}
