import React from 'react';
import {Col, Row} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import ImageHandler from '../../../../../shared/components/ImageHandler';
import {formatPrice} from '../../../../../shared/functions/formatPrice';
import {IOrder} from '../../../../../shared/interfaces/IOrder';

interface IProps {
    order: IOrder | null
}

const SingleOrderTable = ({order}: IProps) => {
    const {t} = useTranslation();
    return (
        <div className={'mb-3'}>
            <Row className={'border-bottom py-2 mb-3 '}>
                <Col xs={6}>
                    <h6 className={'text-uppercase'}>{t('navigation.products')}</h6>
                </Col>
                <Col xs={3} md={2} className={'px-0'}>
                    <h6 className={'text-uppercase'}>{t('general.price')}</h6>
                </Col>
                <Col xs={3} md={2} className={'px-0'}>
                    <h6 className={'text-uppercase'}>{t('general.quantity')}</h6>
                </Col>
                <Col xs={1} md={2} className={'px-0 d-none d-md-block'}>
                    <h6 className={'text-uppercase'}>{t('general.subtotal')}</h6>
                </Col>
            </Row>

            {order?.items?.map((item, index) => {
                const isLastElement = index === order?.items?.length - 1
                return (
                    <Row key={item?.id} className={`pb-3 ${isLastElement ? '' : 'mb-3 border-bottom'}`}>
                        <Col xs={6} md={6} className={'ps-0 '}>
                            <Row>
                                <Col md={4} className={'ps-0 d-none d-md-block'}>
                                    <ImageHandler
                                        className={'product-order-img default-radius'}
                                        src={item?.product?.productPhotos?.[0]?.path}/>

                                </Col>
                                <Col md={4}
                                     className={'d-flex justify-content-center flex-column'}>
                                    <p className="mb-0 fw-bold">
                                        {item?.product?.name || '-'}
                                    </p>

                                    <p className="mb-0 text-muted">
                                        {t('general.description')}: {item?.product?.name || '-'}
                                    </p>
                                    <p className="mb-0 text-muted">
                                        SKU: {item?.product?.sku}
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={5} md={2} className={'ps-0 d-flex align-items-center'}>
                            <p className="mb-0 text-muted">
                                {formatPrice(item?.product?.price || 0)}
                            </p>
                        </Col>
                        <Col xs={1} md={2} className={'ps-0 d-flex align-items-center'}>
                            <p className="mb-0 text-muted">
                                {item?.quantity}
                            </p>
                        </Col>
                        <Col xs={2} md={2} className={'ps-0 d-none d-md-flex align-items-center'}>
                            <p className="mb-0 fw-bold">
                                {formatPrice(item?.price || 0)}
                            </p>
                        </Col>
                    </Row>
                )
            })}

            <div className={'border-bottom py-2 bg-secondary pe-3'}>
                <h6 className={'text-end mb-0'}>
                    TOTAL: {formatPrice(order?.items?.reduce((prev, curr) => curr.price + prev, 0) || 0)}
                </h6>
            </div>
        </div>
    );
};

export default SingleOrderTable;
