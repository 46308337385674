import React from 'react'
import { useTranslation } from 'react-i18next';

interface IProps {
    changeFilterHandler: (name: string, value: string) => void;
    keys: string[]
}

export default function Search({ changeFilterHandler, keys }: IProps) {
    const { t } = useTranslation();

    return (
        <div className='row mb-4'>
            <div className="col-12 px-0">
                {keys.map(key => {
                    return <input
                        key={key}
                        type="text"
                        name={key}
                        className="form-control rounded-0 border border-secondary p-3 mb-3"
                        placeholder={t(`search.${key}`)}
                        onChange={(e) => changeFilterHandler(e.target.name, e.target.value)}
                    />
                })}
            </div>
        </div>
    )
}
