export const productsTableHeaders = [
    { key: 'id', label: 'id' },
    { key: 'name', label: 'name' },
    { key: 'description', label: 'description' },
    { key: 'price', label: 'price' },
    { key: 'category.name', label: 'category' },
    { key: 'quantity', label: 'quantity' },
    { key: 'sku', label: 'sku' },
    { key: 'createdAt', label: 'createdAt' },
    { key: 'updatedAt', label: 'updatedAt' }
];