import React, {useEffect, useState} from 'react';
import './App.scss';
import {Spinner} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import {Route, Routes} from 'react-router-dom';
import {AuthService} from './authentification/login/service';
import Layout from './shared/components/Layout';
import {handleError} from './shared/functions/handleError';
import authSlice from './store/slices/auth.slice';
import {RootState} from './store/store';
import i18n from './utils/localization/i18n';
import {routeList} from './utils/routes';

function App() {
    const token = JSON.parse(localStorage.getItem('token') as string);
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch();
    const {userData} = useSelector((state: RootState) => state.auth);
    const currentRoleRoutes = routeList?.find(route => route.role.some(role => role === userData?.role));

    useEffect(() => {
        if (token) {
            AuthService.me().then(response => {
                dispatch(authSlice.actions.setLoginState({
                    isAuthenticated: true,
                    userData: response
                }))
            }).catch(err => handleError(err)).finally(() => setIsLoading(false));
        } else {
            setIsLoading(false);
        }
    }, [token, dispatch]);
    useEffect(() => {
        const selectedLanguage = localStorage.getItem('language');
        selectedLanguage && i18n.changeLanguage(selectedLanguage);
    }, [])
    return (
        <Layout>
            <>
                {!isLoading ? <Routes>
                        {currentRoleRoutes?.routes?.map(route => {
                            return (
                                <Route path={route?.path} element={route?.component} key={route.path}/>
                            )
                        })}
                    </Routes>
                    :
                    <div className="loading-screen">
                        <Spinner/><h4 className="ms-2">Loading...</h4>
                    </div>
                }
            </>
        </Layout>
    );
}

export default App;
