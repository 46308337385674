import React, {SetStateAction, useEffect, useState} from 'react'
import {Button, Form} from 'react-bootstrap'
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {CategoryService} from '../../categories/service';
import {usePagination} from '../../../../shared/hooks/usePagination';
import {handleError} from '../../../../shared/functions/handleError';
import FileUpload from '../../../../shared/components/FileUpload';
import Fancybox from '../../../../shared/components/Fancybox';
import ImageHandler from '../../../../shared/components/ImageHandler';
import {MdDelete} from 'react-icons/md';
import ConfirmationModal from '../../../../shared/components/ConfirmationModal';
import {ProductService} from '../service';
import {toast} from 'react-toastify';
import {IPhoto} from '../../../../shared/interfaces/IPhoto';
import {ICategory} from '../../../../shared/interfaces/ICategory';
import {IProduct} from '../../../../shared/interfaces/IProduct';

interface IProps {
    currentProduct: IProduct | null;
    setCurrentProduct: React.Dispatch<SetStateAction<IProduct | null>>;
    handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>;
    files?: FileList | null;
    setFiles?: React.Dispatch<SetStateAction<FileList | null>>;
}

export default function ProductForm({currentProduct, setCurrentProduct, handleChange, files, setFiles}: IProps) {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [categories, setCategories] = useState<ICategory[]>([]);
    const {pagination} = usePagination({
        perPage: 0,
    });
    const [selectedImage, setSelectedImage] = useState<IPhoto | null>(null);
    const [isDeleteVisible, setIsDeleteVisible] = useState(false);

    const handleCloseDeleteModal = () => {
        setIsDeleteVisible(false);
        setSelectedImage(null);
    }

    const handleDeleteImage = () => {
        selectedImage?.id && ProductService.deleteProductImg(+selectedImage?.id).then(response => {
            if (response) {
                setCurrentProduct(prev => ({
                    ...prev,
                    productPhotos: prev?.productPhotos?.filter(photo => photo.id !== selectedImage?.id)
                }) as IProduct);
                toast.success(t('general.successfullyDeleted'));
                setSelectedImage(null);
                setIsDeleteVisible(false);
            }
        }).catch(err => handleError(err));
    }

    useEffect(() => {
        CategoryService.getAllCategories(pagination).then(response =>
            setCategories(response?.data)
        ).catch(err => handleError(err));
    }, [pagination]);

    return (
        <div className="row">
            <div className="col-md-6">
                {currentProduct?.id && <>
                    <Form.Label className="mb-0 h6">{t('table.id')}</Form.Label>
                    <input
                        type="text"
                        name="id"
                        value={currentProduct?.id}
                        onChange={handleChange}
                        className={'form-control mb-3 rounded-0 p-3'}
                        disabled
                    /> </>}


                <Form.Label className="mb-0 h6">{t('table.name')}</Form.Label>
                <input type="text"
                       name="name"
                       value={currentProduct?.name}
                       onChange={handleChange}
                       className={'form-control mb-3 rounded-0 p-3'}
                       required
                />

                <Form.Label className="mb-0 h6">{t('table.category')}</Form.Label>
                <select name="categoryId"
                        value={currentProduct?.category?.id}
                        onChange={handleChange}
                        className={'form-select border rounded-0 p-3 mb-3'}
                        required
                >
                    <option hidden> {t('general.selectCategory')} </option>
                    {categories?.map((category) => {
                        return <option key={category?.id} value={category?.id}> {category?.name} </option>
                    })}
                </select>

                <Form.Label className="mb-0 h6">{t('table.price')}</Form.Label>
                <input type="text"
                       name="price"
                       value={currentProduct?.price}
                       onChange={handleChange}
                       className={'form-control mb-3 rounded-0 p-3'}
                       required
                />

                <Form.Label className="mb-0 h6">{t('table.sku')}</Form.Label>
                <input type="text"
                       name="sku"
                       value={currentProduct?.sku}
                       onChange={handleChange}
                       className={'form-control mb-3 rounded-0 p-3'}
                       required
                />

                <Form.Label className="mb-0 h6">{t('table.quantity')}</Form.Label>
                <input type="text"
                       name="quantity"
                       value={currentProduct?.quantity}
                       onChange={handleChange}
                       className={'form-control mb-3 rounded-0 p-3'}
                       required
                />

                <Form.Label className="mb-0 h6">{t('table.description')}</Form.Label>
                <textarea name="description"
                          value={currentProduct?.description}
                          onChange={handleChange}
                          className="form-control mb-3 rounded-0 p-3 h-200px"
                          required/>

                <Form.Label className="mb-0 h6">{t('table.additionalDescription')}</Form.Label>
                <textarea name="additionalDescription"
                          value={currentProduct?.additionalDescription}
                          onChange={handleChange}
                          className="form-control mb-3 rounded-0 p-3 h-200px"
                          required/>
            </div>
            <div className="col-md-6">
                {files !== undefined && setFiles !== undefined &&
                    <div>
                        <Form.Label className="mb-0 h6">{t('table.photos')}</Form.Label>
                        <FileUpload files={files} setFiles={setFiles}
                                    isMultiple={true}
                                    isRequired={!currentProduct?.id}
                                    formatType="image/*"/>
                    </div>}

                {!!currentProduct?.productPhotos?.length &&
                    <div>
                        <Fancybox
                            options={{
                                Carousel: {
                                    infinite: false,
                                },
                            }}>
                            <div className="row">
                                {currentProduct?.productPhotos?.map(photo => {
                                    return (
                                        <div className="col-md-4 mb-3 ps-0 position-relative" key={photo.id}>
                                            <a data-fancybox="gallery" href={photo?.path}>
                                                <div
                                                    onClick={() => {
                                                        setSelectedImage(photo);
                                                        setIsDeleteVisible(true);
                                                    }}
                                                    className="top-right-icon bg-white hover-effect card-border-radius position-absolute"
                                                    style={{
                                                        top: 10,
                                                        right: 20
                                                    }}>
                                                    <MdDelete className="text-primary wh-25px"/>
                                                </div>

                                                <ImageHandler src={photo?.path}
                                                              className={'product-order-img cursor-pointer border'}/>
                                            </a>

                                        </div>
                                    )
                                })}
                            </div>
                        </Fancybox>
                    </div>}

            </div>

            <div className="d-flex justify-content-end mb-5">
                <Button variant="dark"
                        onClick={() => navigate(-1)}
                        className={'text-uppercase'}>
                    {t('buttons.cancel')}
                </Button>
                <Button variant="primary"
                        type="submit"
                        className={'ms-2 text-uppercase'}>
                    {currentProduct?.id ? t('buttons.update') : t('buttons.create')}
                </Button>

            </div>

            <ConfirmationModal
                handleConfirm={handleDeleteImage}
                handleClose={handleCloseDeleteModal} show={isDeleteVisible} title={`image ID: ${selectedImage?.id}`}/>
        </div>
    )
}
