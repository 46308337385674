import React from 'react'
import { IProduct } from '../../../../shared/interfaces/IProduct'
import { Col, Row } from 'react-bootstrap'
import { formatPrice } from '../../../../shared/functions/formatPrice'
import { useTranslation } from 'react-i18next'
import { IOrder } from '../../../../shared/interfaces/IOrder'
import { getTotalPrice } from '../../../../shared/functions/getTotalPrice'

interface IProps {
    cart: IProduct[] | IOrder
    isConfirmation?: boolean
}

export default function OrderDetails({ cart, isConfirmation = false }: IProps) {
    const { t } = useTranslation();
    const products = (isConfirmation ? (cart as IOrder)?.items?.map(product => ({ ...product.product, cartQuantity: product?.quantity })) : cart) as IProduct[];
    return (
        <div className={`${!isConfirmation && 'ms-5'} border border-dark px-5 py-4`}>
            <Row className={'mb-4'}>
                <Col md={12} className={'px-0'}>
                    <h5 className={'text-uppercase'}> {t("cart.yourOrder")} </h5>
                </Col>
            </Row>
            <Row>
                <Col md={6} className={'px-0 border-bottom border-secondary pb-3'}>
                    <h6 className={'text-uppercase'}> {t("cart.product")} </h6>
                </Col>

                <Col md={6} className={' border-bottom border-secondary pb-3'}>
                    <h6 className={'text-uppercase'}> {t("general.subtotal")} </h6>
                </Col>
                {products?.map(product => {
                    return (
                        <React.Fragment key={product?.id}>
                            <Col md={6} className={'d-flex flex-column border-bottom border-secondary px-0'}>
                                <span className="text-muted my-2 small">
                                    {product?.name} x {product?.cartQuantity}
                                </span>
                            </Col>
                            <Col md={6} className={'d-flex flex-column border-bottom border-secondary'}>
                                <span className="text-muted my-2 small">
                                    {formatPrice(product?.price * Number(product?.cartQuantity))}
                                </span>
                            </Col>
                        </React.Fragment>
                    )
                })}
            </Row>

            <Row className={'pt-3'}>
                <Col md={6} className={'px-0'}>
                    <h6 className={'text-uppercase'}> {t("cart.total")} </h6>
                </Col>
                <Col md={6} className="d-flex flex-column">
                    <span className={'text-primary fw-semibold'}>
                        {formatPrice(isConfirmation ? (cart as IOrder)?.total : getTotalPrice(cart as IProduct[]))}
                    </span>

                    {!isConfirmation && <small className={'text-muted'}> {t("cart.cashOnDelivery")} </small>}
                </Col>
            </Row>
        </div>
    )
}
