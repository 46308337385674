import React, {useState} from 'react'
import {Button, Form, InputGroup} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {VscEye, VscEyeClosed} from 'react-icons/vsc';
import {IUser} from '../../../shared/interfaces/IUser';

interface IProps {
    changeHandler: React.ChangeEventHandler<HTMLInputElement>;
    currentUser: IUser | null;
    isDisabled?: boolean;
}

export default function RegisterForm({changeHandler, currentUser, isDisabled = false}: IProps) {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const {t} = useTranslation();

    return (
        <div className="card border-0">
            <div className="card-body p-0">
                {!currentUser?.id && <div className="d-flex justify-content-center">
                    <span className="text-uppercase me-5 cursor-pointer"
                          onClick={() => navigate('/login')}
                    > {t('login.login')} </span>
                    <span
                        className={'text-uppercase cursor-pointer fw-semibold text-decoration-underline'}
                    >
                        {t('login.register')}
                    </span>
                </div>}

                <Form.Control
                    placeholder={t('login.username')}
                    type="text"
                    required
                    disabled={isDisabled}
                    name="profile"
                    onChange={changeHandler}
                    value={currentUser?.profile || ''}
                    className="rounded-0 py-3 my-3"
                />

                <div className="row">
                    <div className="col-md-6 px-0">
                        <Form.Control
                            placeholder={t('general.firstName')}
                            disabled={isDisabled}
                            type="text"
                            required
                            name="firstName"
                            onChange={changeHandler}
                            value={currentUser?.firstName || ''}
                            className="rounded-0 py-3 mb-3"
                        />
                    </div>
                    <div className="col-md-6 px-0 ps-md-2">
                        <Form.Control
                            placeholder={t('general.lastName')}
                            disabled={isDisabled}
                            type="text"
                            required
                            name="lastName"
                            value={currentUser?.lastName || ''}
                            onChange={changeHandler}
                            className="rounded-0 py-3 mb-3"
                        />
                    </div>
                </div>

                <Form.Control
                    placeholder={t('general.email')}
                    type="email"
                    required
                    disabled={isDisabled}
                    name="email"
                    value={currentUser?.email || ''}
                    onChange={changeHandler}
                    className="rounded-0 py-3 mb-3"
                />

                {!currentUser?.id && <div className="mb-3">
                    <Form.Group controlId="formBasicPassword">
                        <InputGroup>
                            <Form.Control
                                type={showPassword ? 'text' : 'password'}
                                disabled={isDisabled}
                                required
                                name="password"
                                placeholder={t('general.password')}
                                onChange={changeHandler}
                                value={currentUser?.password || ''}
                                className="rounded-0 py-3"
                            />

                            <InputGroup.Text>
                                {!showPassword ?
                                    <VscEye className="cursor-pointer" onClick={() => {
                                        setShowPassword((prev) => !prev);
                                    }
                                    }/> : <VscEyeClosed className="cursor-pointer" onClick={() => {
                                        setShowPassword((prev) => !prev);
                                    }
                                    }/>}
                            </InputGroup.Text>
                        </InputGroup>
                    </Form.Group>
                </div>}


                <Form.Control
                    type="text"
                    name="companyName"
                    placeholder={t('general.companyName')}
                    disabled={isDisabled}
                    onChange={changeHandler}
                    value={currentUser?.companyName || ''}
                    className="rounded-0 py-3 mb-3"
                />
                <Form.Control
                    type="text"
                    name="country"
                    required
                    placeholder={t('general.country')}
                    disabled={isDisabled}
                    onChange={changeHandler}
                    value={currentUser?.country || ''}
                    className="rounded-0 py-3 mb-3"
                />
                <Form.Control
                    type="text"
                    name="streetAddress"
                    required
                    disabled={isDisabled}
                    placeholder={t('general.street')}
                    onChange={changeHandler}
                    value={currentUser?.streetAddress || ''}
                    className="rounded-0 py-3 mb-3"
                />

                <Form.Control
                    type="text"
                    name="city"
                    disabled={isDisabled}
                    required
                    placeholder={t('general.city')}
                    onChange={changeHandler}
                    value={currentUser?.city || ''}
                    className="rounded-0 py-3 mb-3"
                />

                <Form.Control
                    type="text"
                    name="zipCode"
                    disabled={isDisabled}
                    required
                    placeholder={t('general.zipCode')}
                    onChange={changeHandler}
                    value={currentUser?.zipCode || ''}
                    className="rounded-0 py-3 mb-3"
                />

                <Form.Control
                    type="text"
                    name="phone"
                    disabled={isDisabled}
                    required
                    placeholder={t('general.phone')}
                    onChange={changeHandler}
                    value={currentUser?.phone || ''}
                    className="rounded-0 py-3 mb-3"
                />

                <small className="text-muted">
                    {t('messages.register')}
                </small>
                {!currentUser?.id &&
                    <Button className="w-100 rounded-0 bg-dark border-0 py-3 mt-3 text-uppercase" type="submit">
                        {t('login.register')}
                    </Button>
                }
            </div>
        </div>
    )
}
