import React from 'react'
import ImageHandler from '../../../../shared/components/ImageHandler'

export default function CategoryCard({ category }: any) {
    return (
        <div className='d-flex flex-column justify-content-center align-items-center'>
            <ImageHandler src={category?.path} className='w-100 h-300px object-fit-cover' />
            <h5 className='text-uppercase mt-2'> {category.name} </h5>
        </div>

    )
}
