import React, {useEffect, useState} from 'react';
import SharedTable from '../../../shared/components/SharedTable';
import {useCachingState} from '../../../shared/hooks/useCachingState';
import {ICategory} from '../../../shared/interfaces/ICategory';
import {categoriesTableHeaders} from './table/categoriesTableHeaders';
import {usePagination} from '../../../shared/hooks/usePagination';
import {CategoryService} from './service';
import {handleError} from '../../../shared/functions/handleError';
import CategoryModal from './single-category/CategoryModal';
import ConfirmationModal from '../../../shared/components/ConfirmationModal';
import {Button} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import Pagination from '../../../shared/components/Pagination';
import Breadcrumbs from '../../../shared/components/Breadcrumbs';
import CategoriesFilter from './components/CategoriesFilter';
import {EOrderType} from '../../../shared/enums/EOrderType';

export default function AdminCategories() {
    const [categories, setCategories] = useCachingState<ICategory[]>('categoriesList', []);
    const [selectedCategory, setSelectedCategory] = useState<ICategory | null>(
        null
    );
    const {
        pagination,
        count,
        setCount,
        changeFilterHandler,
        totalCount,
        setTotalCount,
    } = usePagination({
        perPage: 10,
        paginationKeys: {
            orderType: EOrderType.DESC,
        },
    });
    const [showCategoryModal, setShowCategoryModal] = useState(false);
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const {t} = useTranslation();

    const handleDelete = () => {
        selectedCategory?.id &&
        CategoryService.deleteCategory(selectedCategory?.id)
            .then(() => {
                changeFilterHandler('updatedAt', new Date().getTime());
                toast.success(t('general.successfullyDeleted'));
                setIsDeleteOpen(false);
            })
            .catch((err) => handleError(err));
    };

    const onDeleteClose = () => {
        setIsDeleteOpen(false);
        setSelectedCategory(null);
    };

    useEffect(() => {
        CategoryService.getAllCategories(pagination)
            .then((response) => {
                setCategories(response?.data);
                setCount(Math.ceil(response.count / response.perPage));
                setTotalCount(response?.count);
            })
            .catch((err) => handleError(err));
    }, [pagination, setCount, setTotalCount]);

    return (
        <div>
            <div className="mb-3">
                <Breadcrumbs/>
            </div>

            <div className={'d-flex align-items-center justify-content-between mb-3'}>
                <Button
                    variant="dark"
                    className={'text-uppercase'}
                    onClick={() => {
                        setShowCategoryModal(true);
                    }}
                >
                    {t('buttons.create')}
                </Button>

                <CategoriesFilter
                    changeFilterHandler={changeFilterHandler}
                    pagination={pagination}
                />
            </div>

            <SharedTable
                data={categories}
                headers={categoriesTableHeaders}
                ctaHeaders={['actions']}
                ctaButtons={['view', 'delete']}
                emptyState={
                    <div className={'text-center'}>{t('general.listEmpty')}</div>
                }
                onButtonClick={async (btnName, data) => {
                    if (btnName === 'delete') {
                        setSelectedCategory(data);
                        setIsDeleteOpen(true);
                    }
                    if (btnName === 'view') {
                        setSelectedCategory(data);
                        setShowCategoryModal(true);
                    }
                }}
            />
            <CategoryModal
                show={showCategoryModal}
                setShow={setShowCategoryModal}
                currentCategory={selectedCategory}
                setCurrentCategory={setSelectedCategory}
                changeFilterHandler={changeFilterHandler}
            />

            <ConfirmationModal
                show={isDeleteOpen}
                handleClose={onDeleteClose}
                handleConfirm={handleDelete}
                title={String(selectedCategory?.name)}
            />

            <Pagination
                currentPage={pagination?.page}
                totalPages={count}
                totalItems={totalCount}
                onPerPageClick={(newPerPage) =>
                    changeFilterHandler('perPage', newPerPage)
                }
                onPageClick={(newPage) => changeFilterHandler('page', newPage)}
            />
        </div>
    );
}
