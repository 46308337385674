import { useEffect } from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import LeadingTitle from "../../../shared/components/LeadingTitle";
import { handleError } from "../../../shared/functions/handleError";
import { useCachingState } from "../../../shared/hooks/useCachingState";
import { usePagination } from "../../../shared/hooks/usePagination";
import { IBlog } from "../../../shared/interfaces/IBlog";
import { BlogService } from "../../admin/blog/service";
import BlogCard from "./components/BlogCard";
import BlogFilter from "./components/BlogFilter";

export default function Blog() {
  const [blogPosts, setBlogPosts] = useCachingState<IBlog[]>(
    "blogListUser",
    []
  );
  const { pagination, changeFilterHandler } = usePagination({ perPage: 0 });
  const { t } = useTranslation();

  useEffect(() => {
    BlogService.getAllBlogPosts(pagination)
      .then((response) => {
        setBlogPosts(response?.data);
      })
      .catch((err) => handleError(err));
  }, [pagination]);

  return (
    <div>
      <LeadingTitle title={t("navigation.blog")} />

      <div className="container">
        <div className={"d-flex justify-content-end align-items-center"}>
          <BlogFilter changeFilterHandler={changeFilterHandler} />
        </div>

        <div className="row">
          {!!blogPosts.length ? (
            blogPosts?.map((blog) => {
              return (
                <Col md={6} className={"my-3 ps-0"} key={blog?.id}>
                  <BlogCard blog={blog} />
                </Col>
              );
            })
          ) : (
            <div> {t("general.listEmpty")}</div>
          )}
        </div>
      </div>
    </div>
  );
}
