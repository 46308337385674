import React from 'react';
import {Offcanvas} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';

interface IProps {
    handleClose: () => void;
    children: JSX.Element;
    show: boolean;
}

const FilterDrawer = ({show, handleClose, children}: IProps) => {
    const {t} = useTranslation();

    return (
        <Offcanvas show={show} onHide={handleClose} placement={'end'}>
            <Offcanvas.Header closeButton className={'bg-secondary-light p-4'}>
                <div className={'text-uppercase'}>
                    <h5>
                        {t('general.filterBy')}
                    </h5>
                </div>
            </Offcanvas.Header>
            <Offcanvas.Body>
                {children}
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default FilterDrawer;
