import React from 'react';
import {useTranslation} from 'react-i18next';
import LeadingTitle from '../../../shared/components/LeadingTitle';
import {webshopData} from '../../../utils/data/webshopData';
import GetInTouch from './components/GetInTouch';

export default function Contact() {
    const {t} = useTranslation();
    const keys = ['streetAddress', 'city', 'country', 'email', 'phone'];

    return (
        <>
            <LeadingTitle title={t('footer.contactUs')} className="p-0"/>

            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className={'p-0 mb-5'}>
                            <h5 className={'text-primary mb-3'}> {t(webshopData?.name)} </h5>
                            <div className="d-flex flex-column">
                                {keys?.map((key, index) => {
                                    return (
                                        <span key={index}>
                      {' '}
                                            {
                                                webshopData?.[key as keyof typeof webshopData] as string
                                            }{' '}
                    </span>
                                    );
                                })}
                            </div>
                        </div>

                        <div className={'google-map mb-5'}>
                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d22663.87239769827!2d19.18421016374873!3d44.76074002090236!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475be91cb02d7249%3A0xde5f5ab6329ada88!2sBijeljina!5e0!3m2!1sen!2sba!4v1723114356300!5m2!1sen!2sba"
                                width="w-100"
                                height="450"
                                style={{border: '0px'}}
                                allowFullScreen={false}
                                loading="lazy"
                                title="Bijeljina Google map"
                                referrerPolicy="no-referrer-when-downgrade"
                            ></iframe>
                        </div>

                        <GetInTouch/>
                    </div>
                </div>
            </div>
        </>
    );
}
