import {Dispatch, SetStateAction, useEffect, useState} from 'react';

export function useCachingState<T>(localStorageKey: string, defaultValue: T): [T, Dispatch<SetStateAction<T>>] {
    const [state, setState] = useState(() => {
        const savedValue = localStorage.getItem(localStorageKey);
        return savedValue !== null ? JSON.parse(savedValue) : defaultValue;
    });

    useEffect(() => {
        localStorage.setItem(localStorageKey, JSON.stringify(state));
    }, [localStorageKey, state]);

    return [state, setState];
}

