import React from 'react';
import Table from 'react-bootstrap/Table';
import {useTranslation} from 'react-i18next';
import {getNestedValue} from '../functions/getNestedValue';
import {renderCell} from '../functions/renderCell';

interface ITableProps<T> {
    headers: Array<{ key: string; label: string }>;
    ctaHeaders?: string[];
    ctaButtons?: string[];
    data: T[];
    emptyState?: JSX.Element;
    onButtonClick?: (clickedRow: string, clickedProperty: T) => void;
}

const SharedTable = <T, >({
                              headers,
                              data,
                              emptyState,
                              ctaHeaders = [],
                              ctaButtons = [],
                              onButtonClick
                          }: ITableProps<T>,) => {
    const {t} = useTranslation();

    return (
        <Table responsive>
            <thead className="border-top">
            <tr className="text-uppercase">
                {headers?.map(header => {
                    return (
                        <th className="bg-secondary py-3"
                            key={header?.key}>
                            {t(`table.${header?.label}`)}
                        </th>
                    )
                })}

                {ctaHeaders?.map(header => {
                    return (
                        <th className="bg-secondary py-3"
                            key={header}>
                            {t(`table.${header}`)}
                        </th>
                    )
                })}
            </tr>
            </thead>

            <tbody>
            {!!data?.length ? data?.map((row, rowIndex) => (
                <tr key={rowIndex}>
                    {headers.map((header) => {
                        return <td key={header.key}
                                   className={`${['name', 'description', 'shortDescription'].includes(header.key) ? 'max-w-400 text-truncate' : ''}`}>
                            {renderCell(header.key, getNestedValue(row, header.key))}
                        </td>
                    })}
                    {!!ctaButtons?.length && <td className="pe-0">
                        <div className={'d-flex align-items-center'}>
                            {ctaButtons?.map((button) => (
                                <React.Fragment key={button}>
                                    {renderCell(button, getNestedValue(row, button), t, onButtonClick ? () => onButtonClick(button, row) : undefined)}
                                </React.Fragment>
                            ))}
                        </div>
                    </td>}
                </tr>
            )) : <tr>
                <td colSpan={headers?.length + ctaButtons?.length}>
                    {emptyState}
                </td>
            </tr>}
            </tbody>
        </Table>
    );
};

export default SharedTable;
