import { IBlog } from "../../../shared/interfaces/IBlog";
import { IPagination } from "../../../shared/interfaces/IPagination";
import { requests } from "../../../utils/api/api.service";
import { ADMIN_BLOG } from "../../../utils/api/endpoints";

export const BlogService = {
    getAllBlogPosts: (pagination: IPagination): Promise<{ data: IBlog[], count: number, perPage: number }> =>
        requests.get(ADMIN_BLOG, pagination),

    getBlogById: (blogId: number) =>
        requests.get(`${ADMIN_BLOG}/${blogId}`),

    createBlog: (payload: IBlog) =>
        requests.post(ADMIN_BLOG, payload),

    updateBlog: (payload: IBlog, blogId: number) =>
        requests.put(`${ADMIN_BLOG}/${blogId}`, payload),

    deleteBlog: (blogId: number) =>
        requests.delete(`${ADMIN_BLOG}/${blogId}`)
}