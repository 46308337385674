import React, {useEffect, useState} from 'react';
import SharedTable from '../../../shared/components/SharedTable';
import {useNavigate} from 'react-router-dom';
import {useCachingState} from '../../../shared/hooks/useCachingState';
import {usePagination} from '../../../shared/hooks/usePagination';
import {IFaq} from '../../../shared/interfaces/IFaq';
import {FAQService} from './service';
import {faqTableHeaders} from './table/faqTableHeaders';
import {handleError} from '../../../shared/functions/handleError';
import ConfirmationModal from '../../../shared/components/ConfirmationModal';
import {toast} from 'react-toastify';
import {useTranslation} from 'react-i18next';
import {Button} from 'react-bootstrap';
import Breadcrumbs from '../../../shared/components/Breadcrumbs';
import Pagination from '../../../shared/components/Pagination';
import FaqFilter from './components/FaqFilter';

export default function AdminFAQ() {
    const navigate = useNavigate();
    const {
        pagination,
        count,
        setCount,
        changeFilterHandler,
        totalCount,
        setTotalCount,
    } = usePagination({
        perPage: 10,
    });

    const [listFAQ, setListFAQ] = useCachingState<IFaq[]>('faqList', []);
    const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
    const [selectedFaq, setSelectedFaq] = useState<IFaq | null>(null);
    const {t} = useTranslation();

    const onDeleteClose = () => {
        setIsDeleteOpen(false);
        setSelectedFaq(null);
    };

    const handleDelete = () => {
        FAQService.deleteFaq(selectedFaq?.id as number)
            .then((response) => {
                if (response) {
                    toast.success(t('general.successfullyDeleted'));
                    setIsDeleteOpen(false);
                    setListFAQ((prev) =>
                        prev.filter((faq) => faq.id !== selectedFaq?.id)
                    );
                }
            })
            .catch((err) => handleError(err));
    };

    useEffect(() => {
        FAQService.getAllFaq(pagination)
            .then((response) => {
                setListFAQ(response?.data);
                setCount(Math.ceil(response.count / response.perPage));
                setTotalCount(response?.count);
            })
            .catch((err) => handleError(err));
    }, [pagination, setCount, setTotalCount]);

    return (
        <div>
            <div className="mb-3">
                <Breadcrumbs/>
            </div>

            <div className={'d-flex align-items-center justify-content-between mb-3'}>
                <Button
                    variant="dark"
                    className={'text-uppercase'}
                    onClick={() => {
                        navigate('/admin/faq/create');
                    }}
                >
                    {t('buttons.create')}
                </Button>

                <FaqFilter changeFilterHandler={changeFilterHandler}/>
            </div>

            <SharedTable
                data={listFAQ}
                headers={faqTableHeaders}
                ctaHeaders={['actions']}
                ctaButtons={['view', 'delete']}
                emptyState={
                    <div className={'text-center'}>{t('general.listEmpty')}</div>
                }
                onButtonClick={async (btnName, data) => {
                    if (btnName === 'delete') {
                        setSelectedFaq(data);
                        setIsDeleteOpen(true);
                    }
                    if (btnName === 'view') {
                        navigate(`/admin/faq/edit/${data?.id}`);
                    }
                }}
            />

            <Pagination
                currentPage={pagination?.page}
                totalPages={count}
                totalItems={totalCount}
                onPerPageClick={(newPerPage) =>
                    changeFilterHandler('perPage', newPerPage)
                }
                onPageClick={(newPage) => changeFilterHandler('page', newPage)}
            />

            <ConfirmationModal
                show={isDeleteOpen}
                handleClose={onDeleteClose}
                title={String(selectedFaq?.id)}
                handleConfirm={handleDelete}
            />
        </div>
    );
}
