import React from 'react';

export interface IOptions {
    name: string;
    value: number | string;
}

export interface IProps
    extends React.DetailedHTMLProps<
        React.SelectHTMLAttributes<HTMLSelectElement>,
        HTMLSelectElement
    > {
    defaultLabel?: string;
    options: IOptions[];
}

const SelectDropdown: React.FC<IProps> = ({
    options,
    defaultLabel = 'Default Sorting',
    ...props
}) => {

    return (
        <select className={'form-select-sm form-select'} {...props}>
            <option value={''}>{defaultLabel}</option>
            {options?.map(option => {
                return (
                    <option value={option?.value}>
                        {option?.name}
                    </option>
                )
            })}
        </select>
    );
};

export default SelectDropdown;
