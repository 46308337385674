import React from 'react'
import {useTranslation} from 'react-i18next';
import CategoryCard from './CategoryCard'
import {ICategory} from '../../../../shared/interfaces/ICategory'

// Import Swiper React components
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, Autoplay} from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';

interface IProps {
    categories: ICategory[]
}

export default function MostPreferredCategories({categories}: IProps) {
    const {t} = useTranslation();
    return (
        <div className="mb-5">
            <div className="d-flex flex-column justify-content-center align-items-center mt-5 mb-3">
                <h2 className="text-uppercase"> {t('general.mostPreferredCategories')} </h2>
                <small className="text-muted mt-2"> Najpopularniji brendovi na jednom mjestu. </small>
            </div>

            <Swiper
                spaceBetween={20}
                slidesPerView={4}
                navigation={true}
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                    },
                    400: {
                        slidesPerView: 2,
                    },
                    639: {
                        slidesPerView: 3,
                    },
                    865: {
                        slidesPerView: 4
                    },
                }}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                modules={[Autoplay, Navigation]}
            >
                {categories?.map((category) => {
                    return (
                        <SwiperSlide key={category?.id}>
                            <CategoryCard category={category}/>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </div>
    )
}
