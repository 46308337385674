import FAQ from '../../../app/public/faq';
import Blog from '../../../app/public/blog';
import Contact from '../../../app/public/contact';
import Home from '../../../app/public/home';
import PricePolicy from '../../../app/public/price-policy';
import SingleProduct from '../../../app/public/product';
import PublicProfile from '../../../app/public/profile';
import ReturnPolicy from '../../../app/public/return-policy';
import Shop from '../../../app/public/shop';
import NotFound from '../../../authentification/404';
import Login from '../../../authentification/login';
import Register from '../../../authentification/register';
import SingleUserOrder from '../../../shared/components/my-account/orders/SingleUserOrder';
import Wishlist from '../../../app/public/wishlist';
import Cart from '../../../app/public/cart';
import BlogPost from '../../../app/public/blog/components/BlogPost';

export const publicRoutes = [
    {
        path: '/register',
        component: <Register/>,
    },
    {
        path: '/',
        showInHeader: true,
        title: 'navigation.home',
        component: <Home/>,
    },
    {
        path: '/shop',
        showInHeader: true,
        title: 'navigation.shop',
        component: <Shop/>,
    },
    {
        path: '/blog',
        showInHeader: true,
        title: 'navigation.blog',
        component: <Blog/>,
    },
    {
        path: '/contact',
        showInHeader: true,
        title: 'navigation.contact',
        component: <Contact/>,
    },
    {
        path: '/faq',
        showInHeader: true,
        title: 'FAQ',
        component: <FAQ/>,
    },
    {
        path: '/login',
        showInHeader: true,
        title: 'login.signIn',
        component: <Login/>,
    },
    {
        path: '/myaccount',
        title: 'general.myAccount',
        component: <PublicProfile/>,
    },
    {
        path: '/users/my-orders/:id',
        component: <SingleUserOrder/>,
    },
    {
        path: '/product/:id',
        component: <SingleProduct/>,
    },
    {
        path: '/users/wishlist',
        component: <Wishlist/>,
    },
    {
        path: '/users/cart',
        component: <Cart/>,
    },
    {
        path: '/blog/:id',
        component: <BlogPost/>,
    },
    {
        path: '/return-policy',
        component: <ReturnPolicy/>,
    },
    {
        path: '/price-policy',
        component: <PricePolicy/>,
    },
    {
        path: '*',
        component: <NotFound/>,
    },
];
