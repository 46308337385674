import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { RiSortAsc, RiSortDesc } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import FilterDrawer from '../../../../shared/components/FilterDrawer';
import Search from '../../../../shared/components/Search';
import SelectDropdown from '../../../../shared/components/SelectDropdown';
import { EOrderType } from '../../../../shared/enums/EOrderType';
import { EProductSortingCriteria } from '../../../../shared/enums/ESortingCriteria';
import { handleError } from '../../../../shared/functions/handleError';
import { usePagination } from '../../../../shared/hooks/usePagination';
import { CategoryService } from '../../categories/service';
import { ICategory } from '../../../../shared/interfaces/ICategory';

interface IProps {
    changeFilterHandler: (name: string, value: (string | number | boolean | null)) => void;
    pagination: any;
}

const searchKeys = ['title', 'sku'];

const ProductsFilter = ({ changeFilterHandler, pagination }: IProps) => {
    const { t } = useTranslation();
    const [showFilter, setShowFilter] = useState(false);
    const [categories, setCategories] = useState<ICategory[]>([]);
    const navigate = useNavigate();
    const { pagination: categoriesPagination } = usePagination({ perPage: 0 });

    useEffect(() => {
        CategoryService.getAllCategories(categoriesPagination).then(response =>
            setCategories(response?.data)
        ).catch(err => handleError(err));
    }, [categoriesPagination]);

    return (
        <>
            <div className={'d-flex align-items-center justify-content-between mb-3'}>
                <Button variant="dark" className={'text-uppercase'}
                    onClick={() => navigate('/admin/products/create')}>
                    {t('buttons.create')}
                </Button>
                <div className={'d-flex align-items-center'}>
                    <div className="border-end px-3">
                        <h6 className={'text-uppercase cursor-pointer d-flex align-items-center'}
                            onClick={() => {
                                changeFilterHandler(
                                    'orderType',
                                    pagination?.orderType === EOrderType.ASC ? EOrderType.DESC : EOrderType.ASC
                                )
                            }}>
                            {pagination?.orderType}
                            {pagination?.orderType === EOrderType.ASC ? <RiSortAsc className={'ms-1'} /> :
                                <RiSortDesc className={'ms-1'} />
                            }
                        </h6>
                    </div>
                    <div className="border-end mx-3">
                        <SelectDropdown name={'orderCriteria'}
                            onChange={(event) => {
                                changeFilterHandler('orderCriteria', event?.target?.value)
                            }}
                            options={Object.values(EProductSortingCriteria).map(criteria => ({
                                value: criteria,
                                name: t(`general.${criteria}`)
                            }))}
                        />
                    </div>
                    <h6 className={'text-uppercase cursor-pointer hover-underline-animation'}
                        onClick={() => setShowFilter(true)}>
                        {t("general.filters")}
                    </h6>
                </div>
            </div>

            <FilterDrawer show={showFilter} handleClose={() => setShowFilter(false)}>
                <>
                    <div className="my-3">
                        <SelectDropdown name={'categoryId'}
                            defaultLabel={t('general.filterByCategory')}
                            onChange={(event) => {
                                changeFilterHandler('category', event?.target?.value)
                            }}
                            options={categories?.map(category => ({
                                value: category?.id,
                                name: category?.name
                            }))}
                        />
                    </div>
                    <Search changeFilterHandler={changeFilterHandler} keys={searchKeys} />
                </>

            </FilterDrawer>
        </>
    );
};

export default ProductsFilter;