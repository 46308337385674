import AdminCategories from '../../../app/admin/categories';
import AdminDashboard from '../../../app/admin/dashboard';
import AdminOrders from '../../../app/admin/orders';
import SingleOrder from '../../../app/admin/orders/single-order';
import AdminProducts from '../../../app/admin/products';
import AdminUsers from '../../../app/admin/users';
import SingleUser from '../../../app/admin/users/single-user';
import NotFound from '../../../authentification/404';
import MyAccount from '../../../shared/components/my-account/MyAccount';
import AdminFAQ from '../../../app/admin/faq';
import SingleFAQ from '../../../app/admin/faq/single-faq';
import CreateFaq from '../../../app/admin/faq/single-faq/components/CreateFaq';
import SingleProduct from '../../../app/admin/products/single-product';
import CreateProduct from '../../../app/admin/products/components/CreateProduct';
import AdminBlog from '../../../app/admin/blog';
import SingleBlogPost from '../../../app/admin/blog/components/SingleBlogPost';
import CreateBlogPost from '../../../app/admin/blog/components/CreateBlogPost';

export const adminRoutes = [
    {
        path: '/admin/dashboard',
        showInHeader: true,
        title: 'navigation.dashboard',
        component: <AdminDashboard />
    },
    {
        path: "/admin/products",
        showInHeader: true,
        title: "navigation.products",
        component: <AdminProducts />
    },
    {
        path: "/admin/categories",
        showInHeader: true,
        title: "navigation.categories",
        component: <AdminCategories />
    },
    {
        path: "/admin/users",
        showInHeader: true,
        title: "navigation.users",
        component: <AdminUsers />
    },
    {
        path: "/admin/orders",
        showInHeader: true,
        title: "navigation.orders",
        component: <AdminOrders />
    },
    {
        path: "/admin/blog",
        showInHeader: true,
        title: "Blog",
        component: <AdminBlog />
    },
    {
        path: "/admin/faq",
        showInHeader: true,
        title: "FAQ",
        component: <AdminFAQ />
    },
    {
        path: "/myaccount",
        title: "general.myAccount",
        component: <MyAccount />
    },
    {
        path: "/admin/users/edit/:id",
        component: <SingleUser />
    },
    {
        path: "/admin/orders/edit/:id",
        component: <SingleOrder />
    },
    {
        path: "/admin/faq/edit/:id",
        component: <SingleFAQ />
    },
    {
        path: "/admin/faq/create",
        component: <CreateFaq />
    },
    {
        path: "/admin/products/edit/:id",
        component: <SingleProduct />
    },
    {
        path: "/admin/products/create",
        component: <CreateProduct />
    },
    {
        path: "/admin/blog/create",
        component: <CreateBlogPost />
    },
    {
        path: "/admin/blog/edit/:id",
        component: <SingleBlogPost />
    },
    {
        path: '*',
        component: <NotFound />
    }
]