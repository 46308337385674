export const userTableHeaders = [
    { key: 'id', label: 'id' },
    { key: 'firstName', label: 'firstName' },
    { key: 'lastName', label: 'lastName' },
    { key: 'email', label: 'email' },
    { key: 'phone', label: 'phone' },
    { key: 'country', label: 'country' },
    { key: 'createdAt', label: 'createdAt' }
];

