import React, { useEffect, useState } from 'react'
import FaqForm from './components/FaqForm'
import { IFaq } from '../../../../shared/interfaces/IFaq'
import { useNavigate, useParams } from 'react-router-dom'
import { Form } from 'react-bootstrap';
import { FAQService } from '../service';
import { handleError } from '../../../../shared/functions/handleError';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export default function SingleFAQ() {
    const { id } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [currentFaq, setCurrentFaq] = useState<IFaq | null>(null);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setCurrentFaq((prev) => ({ ...prev, [name]: value }) as IFaq);
    }

    const handleSubmit = () => {
        FAQService.updateFaq(currentFaq as IFaq, Number(id)).then((response) => {
            if (response) {
                toast.success(t("general.successfullyUpdated"));
                navigate(-1);
            }
        }).catch(err => handleError(err));
    }

    useEffect(() => {
        !!id && FAQService.getFaqById(+id).then((response) => {
            setCurrentFaq(response);
        }).catch(err => handleError(err));
    }, [id]);

    return (
        <Form onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
        }}>
            <FaqForm currentFaq={currentFaq} handleChange={handleChange} />
        </Form>
    )
}
