import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next';
import ProductCard from './ProductCard'

// Import Swiper React components
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation, Autoplay} from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
import {ICategory} from '../../../../shared/interfaces/ICategory';

interface IProps {
    categories: ICategory[];
}

export default function MostPopularProducts({categories}: IProps) {
    const [activeCategory, setActiveCategory] = useState<ICategory | null>(null);
    const {t} = useTranslation();
    useEffect(() => {
        if (categories?.length) {
            setActiveCategory(categories[0])
        }
    }, [categories])

    return (
        <div className="mb-5">
            <div className="d-flex flex-column justify-content-center align-items-center mt-5 mb-3">
                <h2 className="text-uppercase  text-uppercase"> {t('general.mostPopularProducts')} </h2>
                <small className="text-muted mt-2"> Najpopularniji proizvodi na jednom mjestu. </small>
                <div className="d-flex flex-wrap align-items-center justify-content-center mt-4 mb-2">
                    {categories?.map(category => {
                        const isActive = activeCategory?.id === category?.id
                        return (
                            <span
                                onClick={() => setActiveCategory(category)}
                                className={`${isActive ? 'fw-semibold' : 'text-muted hover-underline-animation'} cursor-pointer me-3`}
                                key={category?.id}>
                                {category?.name}
                            </span>

                        )
                    })}
                </div>
            </div>


            <Swiper
                spaceBetween={20}
                slidesPerView={4}
                navigation={true}
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                    },
                    400: {
                        slidesPerView: 2,
                    },
                    639: {
                        slidesPerView: 3,
                    },
                    865: {
                        slidesPerView: 4
                    },
                }}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                modules={[Autoplay, Navigation]}
            >
                {activeCategory?.products?.map((product) => {
                    return <SwiperSlide key={product?.id}>
                        <ProductCard product={product}/>
                    </SwiperSlide>

                })}
            </Swiper>
        </div>
    )
}
