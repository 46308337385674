import React from 'react';
import {useTranslation} from 'react-i18next';
import LeadingTitle from '../../../shared/components/LeadingTitle';

const PricePolicy = () => {
    const {t} = useTranslation();
    return (
        <>
            <LeadingTitle title={t('navigation.pricePolicy')} className="p-0"/>
            <div className="container mt-3">
                <h2 className={'mb-3 fw-bold'}>Dostava</h2>
                <p>
                    <b>Za područje BiH</b> osiguravamo dostavu na adresu putem dostavne službe:
                    Državna brza pošta (BH PostExpres / Brze pošte Republike Srpske i Hrvatska
                    pošta Mostar)
                </p>
                <p className="fw-bold text-primary">
                    Cijene za narudžbe ispod 130KM
                </p>
                <p className="fw-bold">
                    Državna brza pošta - 10KM
                </p>
                <p className="fw-bold text-primary">
                    Cijene za narudžbe preko 130KM
                </p>
                <p className="fw-bold">
                    Državna brza pošta - Besplatno
                </p>
                <p className="fw-bold text-primary">
                    Vrijeme dostave:
                </p>
                <p>
                    <b>Državna brza pošta</b> - Vrijeme dostave 24-48 časova najčešće.
                </p>
            </div>
        </>
    );
};

export default PricePolicy;
