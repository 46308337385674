import React from "react";
import { Card } from "react-bootstrap";
import { FaHeart, FaRegHeart } from "react-icons/fa";
import { IProduct } from "../../../../shared/interfaces/IProduct";
import { formatPrice } from "../../../../shared/functions/formatPrice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import wishlistSlice from "../../../../store/slices/wishlist.slice";
import { RootState } from "../../../../store/store";
import cartSlice from "../../../../store/slices/cart.slice";
import { useTranslation } from "react-i18next";

interface IProps {
  shopProduct: IProduct | null;
}

export default function ShopProduct({ shopProduct }: IProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const wishlist = useSelector((state: RootState) => state.wishlist);
  const cart = useSelector((state: RootState) => state.cart);
  const { t } = useTranslation();
  const isWishlist = wishlist.some(
    (product) => product?.id === shopProduct?.id
  );
  const isInCart = cart.some((product) => product?.id === shopProduct?.id);

  const handleWishlistClick = (productId: number, isInList = false) => {
    if (isInList) {
      const updatedList = wishlist.filter((item) => item.id !== productId);
      dispatch(wishlistSlice.actions.setWishlist(updatedList));
      localStorage.setItem("wishlist", JSON.stringify(updatedList));
    } else {
      const updatedList = wishlist.concat({
        ...shopProduct,
        cartQuantity: 1,
      } as IProduct);
      dispatch(wishlistSlice.actions.setWishlist(updatedList));
      localStorage.setItem("wishlist", JSON.stringify(updatedList));
    }
  };

  const handleAddToCart = (
    productId: number,
    isInList = false,
    isInWishlist = false
  ) => {
    if (isInList) {
      const updatedCart = cart.filter((item) => item.id !== productId);
      dispatch(cartSlice.actions.setCart(updatedCart));
      localStorage.setItem("cart", JSON.stringify(updatedCart));
    } else {
      const updatedCart = cart.concat({
        ...shopProduct,
        cartQuantity: 1,
      } as IProduct);

      const updatedWishlist = wishlist.filter(
        (product: IProduct) => product?.id !== productId
      );

      if (isInWishlist) {
        dispatch(wishlistSlice.actions.setWishlist(updatedWishlist));
        localStorage.setItem("wishlist", JSON.stringify(updatedWishlist));
      }

      dispatch(cartSlice.actions.setCart(updatedCart));
      localStorage.setItem("cart", JSON.stringify(updatedCart));
    }
  };

  return (
    <Card className="rounded-0 border-0 h-100 cursor-pointer product-card">
      <div className="position-relative">
        <Card.Img
          variant="top"
          src={shopProduct?.productPhotos?.[0]?.path}
          className="w-100 object-fit-cover h-300px rounded-0"
          onClick={() => navigate(`/product/${shopProduct?.id}`)}
        />
        <button
          className="add-to-cart-button"
          onClick={() =>
            handleAddToCart(Number(shopProduct?.id), isInCart, isWishlist)
          }
        >
          {isInCart ? t("buttons.alreadyAdded") : t("buttons.addToCart")}
        </button>
      </div>
      <Card.Body>
        <div className="text-muted d-flex justify-content-between my-2">
          <small className="text-muted "> {shopProduct?.category?.name} </small>
          {isWishlist ? (
            <FaHeart
              className="text-primary cursor-pointer"
              onClick={() => handleWishlistClick(Number(shopProduct?.id), true)}
            />
          ) : (
            <FaRegHeart
              className="cursor-pointer text-muted"
              onClick={() => handleWishlistClick(Number(shopProduct?.id))}
            />
          )}
        </div>
        <h5 className="mb-1"> {shopProduct?.name} </h5>
        <span> {formatPrice(shopProduct?.price as number)} </span>
      </Card.Body>
    </Card>
  );
}
