export const BASE_URL =
    process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_DEV_MODE
        : process.env.REACT_APP_PRO_MODE;

// USERS
export const LOGIN_URL = BASE_URL + '/users/login';

export const REGISTER_URL = BASE_URL + '/users/register';

export const PROFILE_URL = BASE_URL + '/users/profile';

export const ADMIN_USERS_URL = BASE_URL + '/users/profile-list';

export const ADMIN_USER_URL = BASE_URL + '/users';

export const USER_PASSWORD = BASE_URL + '/users/password-change';

// ORDERS
export const ADMIN_ORDERS_URL = BASE_URL + '/orders';

export const USER_ORDERS_URL = BASE_URL + '/orders/my-orders';

// FAQ
export const ADMIN_FAQ_URL = BASE_URL + '/faq';

// PRODUCTS & CATEGORIES
export const ADMIN_PRODUCTS = BASE_URL + '/products';

export const ADMIN_CATEGORIES = BASE_URL + '/products/categories';

export const ADMIN_PRODUCT_IMG = BASE_URL + '/products/product-image';

// ADMIN DASHBOARD
export const ADMIN_DASHBOARD = BASE_URL + '/users/admin/dashboard';

// COMPANY
export const ADMIN_COMPANY = BASE_URL + '/company';

// BLOG
export const ADMIN_BLOG = BASE_URL + '/blog';