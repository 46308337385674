import {Button, Form} from 'react-bootstrap';
import {useTranslation} from 'react-i18next';

export default function GetInTouch() {
    const {t} = useTranslation();


    return (
        <div className="my-5">
            <h4 className="mb-3"> {t('general.getInTouch')} </h4>
            <form data-netlify="true" name="contact">
                <Form.Control
                    type="text"
                    name="firstName"
                    required
                    placeholder={t('general.firstName')}
                    className="form-control rounded-0 p-3"
                />

                <Form.Control
                    type="text"
                    name="lastName"
                    required
                    placeholder={t('general.lastName')}
                    className="form-control rounded-0 p-3 my-3"
                />

                <Form.Control
                    type="email"
                    name="email"
                    required
                    placeholder={t('general.email')}
                    className="form-control rounded-0 p-3 my-3"
                />

                <Form.Control
                    as="textarea"
                    required
                    name={'message'}
                    rows={5}
                    className="form-control rounded-0 p-3 mb-3"
                    placeholder={t('general.yourMessage')}
                />

                <Button variant="dark" className="text-uppercase w-25 p-3" type="submit">
                    {t('buttons.submit')}
                </Button>
            </form>
        </div>
    );
}
