import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import en from './languages/en.json';
import sr from './languages/sr.json';

export const resources = {
    // list of languages
    sr: {
        translation: sr,
    },
    en: {
        translation: en,
    },
    // de: {
    //     translation: de,
    // },

};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        compatibilityJSON: 'v3', //issue on older versions of phone
        resources,
        defaultNS: 'translation',
        fallbackLng: 'sr',
    });

export default i18n;
