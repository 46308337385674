export enum ESortingCriteria {
    DATE = 'createdAt',
    TOTAL = 'total',
}

export enum EProductSortingCriteria {
    DATE = 'createdAt',
    PRICE = 'price',
}

