import { TFunction } from 'i18next';
import React from 'react';
import BadgeStatus from '../components/BadgeStatus';
import { EOrderStatus } from '../enums/EOrderStatus';
import { formatDate } from './formatDate';
import { formatPrice } from './formatPrice';
import { getCtaColor } from './getCtaColor';
import { clearHTML } from './clearHTML';

export const renderCell = (
    key: string,
    value: string | number,
    t?: TFunction<'translation', undefined>,
    onButtonClick?: () => void
) => {
    if (['createdAt', 'updatedAt'].includes(key)) {
        return formatDate(value as string)
    }
    if (key === 'status') {
        return <BadgeStatus status={value as EOrderStatus} />
    }
    if (['total', 'price'].includes(key)) {
        return formatPrice(Number(value))
    }
    if (['view', 'delete'].includes(key)) {
        return (
            <button type="button" className={`w-100 text-uppercase btn btn-${getCtaColor(key)} me-1 btn-sm`}
                onClick={onButtonClick}
            >
                {t ? t(`buttons.${key}`) : key}
            </button>
        )
    }
    return clearHTML(String(value || '-'));
}