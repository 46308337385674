import React, { useEffect, useState } from 'react'
import { Form } from 'react-bootstrap'
import ProductForm from '../components/ProductForm';
import { useNavigate, useParams } from 'react-router-dom';
import { IProduct } from '../../../../shared/interfaces/IProduct';
import { ProductService } from '../service';
import { handleError } from '../../../../shared/functions/handleError';
import { multipartFormData } from '../../../../utils/api/api.service';
import { ADMIN_PRODUCTS } from '../../../../utils/api/endpoints';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export default function SingleProduct() {
    const { id } = useParams();
    const [currentProduct, setCurrentProduct] = useState<IProduct | null>(null);
    const [files, setFiles] = useState<FileList | null>(null);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleSubmit = () => {
        let dataToSend = { ...currentProduct };
        delete dataToSend.productPhotos;

        multipartFormData(files && files, `${ADMIN_PRODUCTS}/${currentProduct?.id}`, dataToSend, 'put', 'files', true).then((response) => {
            if (response) {
                toast.success(t("general.successfullyUpdated"));
                navigate(-1);
            }
        }).catch(err => handleError(err));
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = event.target;
        setCurrentProduct(prev => ({ ...prev, [name]: value }) as IProduct);
    }

    useEffect(() => {
        id && ProductService.getProductById(+id).then(response => {
            setCurrentProduct(response);
        }).catch(err => handleError(err));
    }, [id]);

    return (
        <Form onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
        }}>
            <ProductForm currentProduct={currentProduct}
                setCurrentProduct={setCurrentProduct}
                handleChange={handleChange}
                files={files}
                setFiles={setFiles} />
        </Form>
    )
}
