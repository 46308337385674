import React from 'react';
import {Badge} from 'react-bootstrap';
import {EOrderStatus} from '../enums/EOrderStatus';
import {getStatusColor} from '../functions/getStatusColor';

interface IProps {
    status: EOrderStatus
}

const BadgeStatus = ({status}: IProps) => {
    return (
        <Badge className={`bg-${getStatusColor(status)}`}>
            {status?.toUpperCase()}
        </Badge>
    );
};

export default BadgeStatus;
