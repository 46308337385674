import React from 'react'
import ImageHandler from './ImageHandler';

interface IProps {
    title: string,
    className?: string
}

const titleBgImg = '/images/title_bg.webp';

export default function LeadingTitle({title, className}: IProps) {
    return (

        <>
            <div className="position-relative">
                <ImageHandler src={titleBgImg} className="w-100 title-bg-img"/>

                <div className="container">
                    <div className={`${className || ''} my-3`}>
                        <h1 className="fw-bold text-uppercase display-3 title-position"> {title} </h1>
                    </div>
                </div>
            </div>
        </>
    )
}
